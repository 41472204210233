export default function getAddressIndex(
  addresses: Array<any>,
  address_id: number
): number {
  let arrayLength = addresses.length;
  for (let i = 0; i < arrayLength; i++) {
    if (addresses[i].id === address_id) {
      return i;
    }
  }

  return -1;
};
