import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import AvatarCard from "../avatarCard";
import InformationCard from "./informationCard";
import MapCard from "./mapCard";
import RelationCard from "./relationCard";
import PaymentCard from "./paymentCard";
import ReviewCard from "./reviewCard";
import CommunicationCard from "./communicationCard";
import AbuseCard from "./abuseCard";
import TimelineCard from "./timelineCard";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";

const Profile = () => {
  const {
    loadingProfileData,
    loadingProfileEvents,
    setUserId,
    drawerData,
    openDrawer,
    setOpenDrawer,
  } = useContext(ApiBbsContext);
  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    if (userId) setUserId(Number(userId));
  }, [userId]);

  return (
    <>
      {loadingProfileData ? (
        <h3>Chargement...</h3>
      ) : (
        <div>
          <Grid container spacing={1} style={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs style={{ flexGrow: 2 }}>
                  <AvatarCard type={"profile"} />
                </Grid>
                <Grid item xs style={{ display: "flex", flexGrow: 3 }}>
                  <Grid
                    container
                    spacing={1}
                    style={{ flexDirection: "column", flexGrow: 1 }}
                  >
                    <Grid item xs style={{ flexGrow: 1 }}>
                      <InformationCard />
                    </Grid>
                    <Grid item xs style={{ flexGrow: 2 }}>
                      <MapCard />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs style={{ flexGrow: 1 }}>
                  <RelationCard />
                </Grid>
                <Grid item xs style={{ display: "flex", flexGrow: 2 }}>
                  <Grid
                    container
                    spacing={1}
                    style={{ flexDirection: "column", flexGrow: 1 }}
                  >
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <PaymentCard />
                        </Grid>
                        <Grid item xs>
                          <ReviewCard />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid container spacing={1}>
                        <Grid item xs>
                          <CommunicationCard />
                        </Grid>
                        <Grid item xs>
                          <AbuseCard />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {loadingProfileEvents ? (
              <h3>Chargement...</h3>
            ) : (
              <Grid item xs={12}>
                <TimelineCard />
              </Grid>
            )}
          </Grid>
          <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
            {drawerData}
          </Drawer>
        </div>
      )}
    </>
  );
};

export default Profile;
