import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {
  withStyles,
  createStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import FacebookIcon from "@material-ui/icons/Facebook";
import SmsIcon from "@material-ui/icons/Sms";
import AppleIcon from "@material-ui/icons/Apple";


import { auth, facebookProvider, appleProvider } from "../../utils/firebase/firebase";
import { signInWithRedirect, signInWithPhoneNumber, ConfirmationResult , RecaptchaVerifier, FacebookAuthProvider } from 'firebase/auth';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(1, "auto", 4),
      display: "flex",
    },
    buttonFacebook: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: "#3B5998",
      color: "#FFF",
      borderRadius: 100,
      "&:hover": {
        backgroundColor: "#3B5998 !important",
      },
      textTransform: "none",
    },
    button: {
      margin: theme.spacing(0, 0, 2),
      color: "#000",
      borderRadius: 100,
      textTransform: "none",
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: 10,
    },
    progess: {
      position: "absolute",
    },
  });

const useStyles = makeStyles(styles);

const Login = () => {
  let navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [
    confirmationResult,
    setConfirmationResult,
  ] = useState<ConfirmationResult | null>(null);
  const [error, setError] = useState<{
    phone: string;
    code: string;
    general: string;
  }>({ phone: "", code: "", general: "" });
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [loadingSMS, setLoadingSMS] = useState(false);
  const [smsFormVisible, setSmsFormVisible] = useState(false);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingApple, setLoadingApple] = useState(false);

  const { user, loadingUser } = useContext(ApiBbsContext);

  useEffect(() => {
    if (!loadingUser && user) navigate("/user");
  }, [user, loadingUser]);

  const captchaRef = useRef(null);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === "phone") {
      setPhone(value);
    }

    if (name === "code") {
      setCode(value);
    }
  };

  const handleSubmitFacebook = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setLoadingFacebook(true);
    signInWithRedirect(auth, facebookProvider);
  };


  const handleSubmitSMS = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setLoadingSMS(true);
    setSmsFormVisible(!smsFormVisible);
    setLoadingSMS(false);
  };

  const handleSubmitSMSPhone = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setLoadingPhone(true);
    const regex = RegExp("0([0-9]+)");
    if (regex.test(phone)) {
      const phoneWithIdentifier = phone.replace(regex, "+33$1");
      console.info("Phone number with identifier", phoneWithIdentifier);
      OnSubmitPhone(phoneWithIdentifier);
    } else setLoadingPhone(false);
  };

  const OnSubmitPhone = async (phone: string) => {
    signInWithPhoneNumber(
      auth,
      phone,
      new RecaptchaVerifier("recaptcha-container",
        {
          size: "normal",
          callback: (response: any) => {
            console.info("Captcha", response);
          }
        }, auth))
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.info("Result", confirmationResult);
        setConfirmationResult(confirmationResult);
        setLoadingPhone(false);
      })
      .catch((error) => {
        // Error;
        // ...
        console.error("Error", error);
      });
  };

  const handleSubmitSMSCode = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setLoadingCode(true);
    if (confirmationResult && code)
      confirmationResult
        .confirm(code)
        .then((result) => {
          console.info("Result", result);
          navigate("/");
          setLoadingCode(false);
        })
        .catch((error) => {
          // Error; SMS not sent
          // ...
          console.error("Error", error);
        });
  };

  const handleSubmitApple = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setLoadingApple(true);
    appleProvider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: "fr",
    });
    signInWithRedirect(auth, appleProvider);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {/* <Button
          type="button"
          fullWidth
          className={classes.buttonFacebook}
          startIcon={<FacebookIcon />}
          size="large"
          onClick={handleSubmitFacebook}
        >
          Connexion avec Facebook
          {loadingFacebook && (
            <CircularProgress size={30} className={classes.progess} />
          )}
        </Button> */}
        <Button
          type="button"
          fullWidth
          variant="outlined"
          className={classes.button}
          startIcon={<SmsIcon />}
          size="large"
          onClick={handleSubmitSMS}
        >
          Connexion avec SMS
          {loadingSMS && (
            <CircularProgress size={30} className={classes.progess} />
          )}
        </Button>
        <Slide direction="right" in={smsFormVisible} mountOnEnter unmountOnExit>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Numéro de téléphone"
              name="phone"
              type="number"
              autoComplete="tel-national"
              autoFocus
              helperText={error.phone}
              error={error.phone ? true : false}
              onChange={handleChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmitSMSPhone}
              disabled={loadingPhone || !phone}
            >
              Se connecter
              {loadingPhone && (
                <CircularProgress size={30} className={classes.progess} />
              )}
            </Button>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              type="number"
              autoComplete="one-time-code"
              autoFocus
              helperText={error.code}
              error={error.code ? true : false}
              onChange={handleChange}
              disabled={!confirmationResult}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmitSMSCode}
              disabled={loadingCode || !code}
            >
              Envoyer
              {loadingCode && (
                <CircularProgress size={30} className={classes.progess} />
              )}
            </Button>
            {error.general && (
              <Typography variant="body2" className={classes.customError}>
                {error.general}
              </Typography>
            )}
          </form>
        </Slide>
        <div id="recaptcha-container" ref={captchaRef}></div>
        <Button
          type="button"
          fullWidth
          variant="outlined"
          className={classes.button}
          startIcon={<AppleIcon />}
          size="large"
          onClick={handleSubmitApple}
        >
          Connexion avec Apple
          {loadingApple && (
            <CircularProgress size={30} className={classes.progess} />
          )}
        </Button>
      </div>
    </Container>
  );
};

export default Login;
