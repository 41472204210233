const firebaseConfig = {
  apiKey: "AIzaSyDkrJW-qQu_a2YSFMHXLDVuBIwkRId0fhM",
  authDomain: "babysittor-1286.firebaseapp.com",
  databaseURL: "https://babysittor-1286.firebaseio.com",
  projectId: "babysittor-1286",
  storageBucket: "babysittor-1286.appspot.com",
  messagingSenderId: "678867955195",
  appId: "1:678867955195:web:8a50d3f1ffe035f9092f5e",
};

export default firebaseConfig;