import GoogleMapReact from "google-map-react";
import RoomIcon from '@material-ui/icons/Room';
import Typography from "@material-ui/core/Typography";

const googleMapsApiKey = "AIzaSyBhfoREJqH9EoZpV7MkClPfPiyd2th9f3c";

type locationProps = {
  address: string;
  lat: number;
  lng: number;
};

type mapProps = {
  location: locationProps;
  zoomLevel: number;
};

const LocationPin = ({address}: locationProps) => (
  <div style={{width: "300px"}}>
    <RoomIcon style={{color: "#F00", fontSize: "2rem"}}/>
    <Typography variant="body2" component="p" style={{ color: "#000" }}>{address}</Typography>
  </div>
)

const Map = ({ location, zoomLevel }: mapProps) => (
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsApiKey }}
        center={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin lat={location.lat}
          lng={location.lng}
          address={location.address} />
      </GoogleMapReact>
);

export default Map;
