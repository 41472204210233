import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import Moment from "moment";
import "moment/locale/fr";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StarIcon from "@material-ui/icons/Star";
import PersonIcon from "@material-ui/icons/Person";
import ImageIcon from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#515E78",
    color: "#FFF",
  },
  oppositeContent: {
    flex: 0,
    padding: 0,
  },
  paper: {
    padding: "6px 16px",
  },
});

const NotificationsCard = () => {
  const {
    notificationsData,
    loadingNotificationsData,
    setNotificationsDataLimit,
  } = useContext(ApiBbsContext);
  const navigate = useNavigate();
  const classes = useStyles();

  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setNotificationsDataLimit(limit);
  }, [limit]);

  const getOverallScore = (context: any) => {
    return (
      <span style={{ display: "flex", marginRight: 10 }}>
        <ArrowRightAltIcon fontSize="small" style={{ marginRight: "5px" }} />
        {Math.round(context.overall_score)} pts
      </span>
    );
  };

  const getFavoriteSpan = (context: any) => {
    if (Boolean(context.is_favorite)) {
      return (
        <span style={{ display: "flex", marginRight: 10 }}>
          <StarIcon fontSize="small" style={{ color: "#f2e724" }} />
        </span>
      );
    }
  };

  const getRelationDistanceSpan = (context: any) => {
    try {
      return (
        <span style={{ display: "flex", marginRight: 10 }}>
          {context.overall_score_metadata.relation_score.separation_degree}e
          <PersonIcon fontSize="small" />
        </span>
      );
    } catch (error) {
      return "";
    }
  };

  const getReviewSpan = (context: any) => {
    try {
      if (context.overall_score_metadata.review_score.score > 0) {
        return (
          <span style={{ display: "flex", marginRight: 10 }}>
            {context.overall_score_metadata.review_score.reviews_total_count}
            <StarIcon fontSize="small" />
          </span>
        );
      }
    } catch (error) {
      return "";
    }
  };

  const getPictureSpan = (context: any) => {
    try {
      if (context.overall_score_metadata.picture_score.score > 0) {
        return (
          <span style={{ display: "flex", marginRight: 10 }}>
            {context.overall_score_metadata.picture_score.pictures_count}
            <ImageIcon fontSize="small" />
          </span>
        );
      }
    } catch (error) {
      return "";
    }
  };

  const getDescriptionSpan = (context: any) => {
    try {
      if (context.overall_score_metadata.description_score.score > 0) {
        return (
          <span style={{ display: "flex", marginRight: 10 }}>
            <DescriptionIcon fontSize="small" />
          </span>
        );
      }
    } catch (error) {
      return "";
    }
  };

  const getDistanceSpan = (context: any) => {
    let d = 0;
    try {
      d = context.overall_score_metadata.distance_score.distance;
    } catch (error) {
      d = context.start_distance;
    }
    return (
      <span style={{ display: "flex", marginRight: 10 }}>
        {d}m
        <LocationOnIcon fontSize="small" />
      </span>
    );
  };

  const getScoreDetails = (context: any) => {
    let result =
      " a " + Math.round(context.overall_score).toString() + "pts : ";

    if ("overall_score_metadata" in context) {
      let md = context["overall_score_metadata"];
      if ("relation_score" in md) {
        if (md.relation_score.separation_degree === 1) {
          result +=
            "Il est directement relié au parent" +
            " (" +
            md.relation_score.score.toString() +
            "pts)";
        } else {
          result +=
            "Il est relié au parent au " +
            md.relation_score.separation_degree.toString() +
            "ème degré" +
            " (" +
            md.relation_score.score.toString() +
            "pts)";
        }
      }

      if ("distance_score" in md) {
        result +=
          ", se situe à " +
          md.distance_score.distance.toString() +
          "m" +
          " (" +
          Math.round(md.distance_score.score).toString() +
          "pts)";
      }

      if ("review_score" in md && md.review_score.score > 0) {
        result +=
          ", a " +
          md.review_score.reviews_total_count.toString() +
          " notes" +
          " (" +
          md.review_score.score.toString() +
          "pts)";
      }

      if ("picture_score" in md) {
        if (md.picture_score.score > 0) {
          result +=
            ", a " +
            md.picture_score.pictures_count.toString() +
            " photos" +
            " (" +
            md.picture_score.score.toString() +
            "pts)";
        } else {
          result +=
            ", a " +
            md.picture_score.pictures_count.toString() +
            " photos" +
            " (" +
            md.picture_score.score.toString() +
            "pts)";
        }
      }

      if ("description_score" in md && md.description_score.score > 0) {
        result +=
          " et a une description complète" +
          " (" +
          md.description_score.score.toString() +
          "pts)";
      }
    } else {
      result +=
        "Il se situe à " +
        context.start_distance.toString() +
        "m, marque " +
        context.review_score.toString() +
        "pts pour ses notes, " +
        context.description_score.toString() +
        "pts pour sa description et " +
        (
          context.overall_score -
          context.description_score -
          context.review_score
        ).toString() +
        "pts pour divers critères (scores de distance, gens_de_confiance, photos, déjà booké)";
    }

    return result;
  };

  return (
    <>
      <Card>
        <CardHeader className={classes.header} title="Notifications" />
        <CardContent>
          {loadingNotificationsData ? (
            <Typography variant="h6" component="p">
              Chargement des notifications...
            </Typography>
          ) : notificationsData && notificationsData.count > 0 ? (
            <Timeline align="left">
              {notificationsData.data &&
                notificationsData.data.map((notification: any) => (
                  <TimelineItem key={notification.id}>
                    <TimelineOppositeContent
                      className={classes.oppositeContent}
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography
                          variant="body2"
                          component="span"
                          style={{
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              marginRight: 10,
                            }}
                            onClick={() =>
                              navigate(
                                "/user/" + notification.context.data.user_id
                              )
                            }
                          >
                            <b>{notification.context.data.user_first_name}</b>
                          </span>
                          {getFavoriteSpan(notification.context.data)}
                          {getRelationDistanceSpan(notification.context.data)}
                          {getDistanceSpan(notification.context.data)}
                          {getReviewSpan(notification.context.data)}
                          {getPictureSpan(notification.context.data)}
                          {getDescriptionSpan(notification.context.data)}
                          {getOverallScore(notification.context.data)}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                            style={{
                              display: "flex",
                            }}
                          >
                            {Moment(notification.to_be_sent_at).format("LLL")} (UTC)
                          </Typography>
                        </Typography>
                        <Typography variant="body2" component="p">
                          <Link
                            href={
                              "/user/" + notification.context.data.user_id
                            }
                            target="_blank"
                          >
                            {notification.context.data.user_id}
                          </Link>{" "}
                          {getScoreDetails(notification.context.data)}
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          ) : (
            <Typography variant="h5" component="p">
              Aucune notification
            </Typography>
          )}
        </CardContent>
        {notificationsData && notificationsData.total_count > limit && (
          <CardActions>
            <Button
              variant="text"
              color="primary"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setLimit(limit + 10);
              }}
            >
              Voir plus
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default NotificationsCard;
