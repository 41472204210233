import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DevicesIcon from "@material-ui/icons/Devices";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import SmsIcon from "@material-ui/icons/Sms";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#EBA068",
    color: "#FFF",
  },
});

const CommunicationCard = () => {
  const { profileData, setDrawerDataRaw } = useContext(ApiBbsContext);
  const classes = useStyles();

  return (
    profileData && (
      <Card>
        <CardHeader className={classes.header} title="Communications" />
        <CardContent>
          <List dense={true}>
            {profileData.devices.total_count > 0 &&
              (profileData.devices.total_count === 1 ? (
                <ListItem>
                  <ListItemIcon>
                    <DevicesIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {profileData.devices.data[0].manufacturer +
                        " " +
                        profileData.devices.data[0].model +
                        (profileData.devices.data[0].uninstalled_at
                          ? ", appli désinstallée"
                          : ", appli installée")}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ) : (
                <ListItem>
                  <ListItemIcon>
                    <DevicesIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        cursor:
                          profileData.devices.total_count > 0
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() =>
                        profileData.devices.total_count > 0 &&
                        setDrawerDataRaw(profileData.devices.data, null)
                      }
                    >
                      {profileData.devices.total_count + " devices"}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            <ListItem>
              <ListItemIcon>
                <NotificationsActiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.notifications.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.notifications.total_count > 0 &&
                    setDrawerDataRaw(profileData.notifications.data, null)
                  }
                >
                  {profileData.notifications.total_count +
                    (profileData.notifications.total_count > 1
                      ? " notifications reçues"
                      : " notification")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SmsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.sms.total_count > 0 ? "pointer" : "default",
                  }}
                  onClick={() =>
                    profileData.sms.total_count > 0 &&
                    setDrawerDataRaw(profileData.sms.data, null)
                  }
                >
                  {profileData.sms.total_count +
                    (profileData.sms.total_count > 1
                      ? " SMS reçus"
                      : " SMS reçu")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.emails.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.emails.total_count > 0 &&
                    setDrawerDataRaw(profileData.emails.data, null)
                  }
                >
                  {profileData.emails.total_count +
                    (profileData.emails.total_count > 1
                      ? " emails reçus"
                      : " email reçu")}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )
  );
};

export default CommunicationCard;
