import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";

const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      verticalAlign: "top",
    },
  });

const useStyles = makeStyles(styles);

const Users = () => {
  const { usersData, loadingUsersData, setUsersSearchInput } = useContext(
    ApiBbsContext
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(searchParams.get("q") || "");

  const classes = useStyles();

  useEffect(() => {
    setUsersSearchInput(searchInput);
  }, []);

  useEffect(() => {
    setSearchInput(searchParams.get("q") || "")
    setUsersSearchInput(searchParams.get("q") || "");
  }, [searchParams])

  const Reviews = (score: number, count: number) => {
    if (!score) {
      return;
    }
    let stars = [];
    let rounded_score = Math.floor(score);
    for (let i = 0; i < rounded_score; i++) {
      stars.push(<StarIcon key={i} fontSize="small" />);
    }
    if (score > rounded_score) {
      stars.push(<StarHalfIcon key="half" fontSize="small" />);
    }
    for (let i = 0; i < 5 - stars.length; i++) {
      stars.push(<StarBorderIcon key={5 - i} fontSize="small" />);
    }
    stars.push(<span key="hello">&ensp;</span>);
    stars.push(
      <Box
        key="count"
        color="white"
        bgcolor="#68A0A5"
        paddingX="5px"
        borderRadius="3px"
        style={{ display: "inline-flex" }}
      >
        <Typography variant="caption" component="span">
          <b>{count}</b>
        </Typography>
      </Box>
    );
    return <div style={{ display: "flex", alignItems: "center" }}>{stars}</div>;
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={8}>
                <TextField
                  id="search-textfield"
                  label="Nom, Téléphone, Email, ..."
                  value={searchInput}
                  fullWidth
                  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                    if (e.key === "Enter") {
                      setSearchParams("q=" + searchInput);
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(event.currentTarget.value as string);
                  }}
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SearchIcon />}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    setSearchParams("q=" + searchInput);
                  }}
                >
                  Chercher
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Table aria-label="table des utilisateurs" size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Genre</TableCell>
                <TableCell>OS</TableCell>
                <TableCell>Téléphone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Adresse</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingUsersData ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="h5" component="p">
                      Chargement...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : usersData && usersData.count > 0 ? (
                usersData.data.map((user: any) => (
                  <TableRow key={user.id}>
                    <TableCell className={classes.tableCell}>
                      {user.default_picture_url ? (
                        <img
                          src={user.default_picture_url}
                          style={{ maxWidth: 80 }}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Pas d'image
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div>
                        <Link
                          style={{ cursor: "pointer" }}
                          color="inherit"
                          onClick={() => navigate("/user/" + user.id)}
                        >
                          {user.first_name} {user.last_name}
                        </Link>
                        <div>
                          <Link href={"/user/" + user.id} target="_blank">
                            ({user.id})
                          </Link>
                          &ensp;
                          {user.banned && (
                            <Box
                              color="white"
                              bgcolor="#F00"
                              paddingX="5px"
                              borderRadius="3px"
                              style={{ display: "inline-flex" }}
                            >
                              <Typography variant="caption" component="span">
                                <b>banni</b>
                              </Typography>
                            </Box>
                          )}
                        </div>
                        {Reviews(
                          user.average_review_score,
                          user.reviews.total_count
                        )}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div style={{ margin: 0 }}>
                        {user.role && user.role.name === "parent" && (
                          <Box
                            color="white"
                            bgcolor="#55C9A6"
                            paddingX="5px"
                            borderRadius="3px"
                            style={{ display: "inline-flex" }}
                          >
                            <Typography variant="caption" component="span">
                              <b>parent</b>
                            </Typography>
                          </Box>
                        )}
                        {user.is_subscribed &&
                          user.role &&
                          user.role.name === "parent" && (
                            <Box
                              color="white"
                              bgcolor="#B4A1DD"
                              paddingX="5px"
                              borderRadius="3px"
                              style={{
                                display: "inline-flex",
                                marginLeft: "3px",
                              }}
                            >
                              <Typography variant="caption" component="span">
                                <b>abonné</b>
                              </Typography>
                            </Box>
                          )}
                        {user.role && user.role.name === "babysitter" && (
                          <Box
                            color="white"
                            bgcolor="#79B0EC"
                            paddingX="5px"
                            borderRadius="3px"
                            style={{ display: "inline-flex" }}
                          >
                            <Typography variant="caption" component="span">
                              <b>babysitter</b>
                            </Typography>
                          </Box>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.gender}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.devices.data.length > 0
                        ? user.devices.data[0].os
                        : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.telephone}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.addresses.data.length > 0 ? (
                        user.addresses.data.map((a: any, i: number) =>
                          a.id === user.current_address_id ? (
                            user.addresses.data.length > 1 ? (
                              <div key={a.id} style={{ margin: 0 }}>
                                {a.google_formatted_address}{" "}
                                <Box
                                  color="white"
                                  bgcolor="#79B0EC"
                                  paddingX="5px"
                                  borderRadius="3px"
                                  style={{ display: "inline-flex" }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="span"
                                  >
                                    <b>actuelle</b>
                                  </Typography>
                                </Box>
                              </div>
                            ) : (
                              <div key={a.id} style={{ margin: 0 }}>
                                {a.google_formatted_address}
                              </div>
                            )
                          ) : (
                            <p key={a.id} style={{ margin: 0 }}>
                              {a.google_formatted_address}
                            </p>
                          )
                        )
                      ) : (
                        <p></p>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" component="p">
                      Aucun résultat trouvé
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default Users;
