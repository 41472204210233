import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";
import Moment from "moment";
import "moment/locale/fr";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      verticalAlign: "top",
    },
    paper: {
      padding: "20px",
      width: "40vw",
    },
    popover: {
      pointerEvents: "none",
    },
  });

const useStyles = makeStyles(styles);

const Abuses = () => {
  const {
    abusesData,
    loadingAbusesData,
    setAbusesDataSearchInput,
    treatAbuse,
  } = useContext(ApiBbsContext);
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const [treated, setTreated] = useState<string>("all");

  const [openedPopoverIdReason, setOpenedPopoverIdReason] = useState<
    number | null
  >(null);
  const [anchorElReason, setAnchorElReason] = useState<HTMLElement | null>(
    null
  );

  const [openedPopoverIdAdmin, setOpenedPopoverIdAdmin] = useState<
    number | null
  >(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState<HTMLElement | null>(null);

  const [open, setOpen] = useState(false);
  const [abuseIdToTreat, setAbuseIdToTreat] = useState<number | null>(null);
  const [adminNote, setAdminNote] = useState<string | null>(null);

  const classes = useStyles();

  useEffect(() => {
    setAbusesDataSearchInput(searchInput, limit, { treated: treated });
  }, [limit, treated]);

  const handleHandled = (
    event: React.MouseEvent<HTMLElement>,
    newTreated: string
  ) => {
    if (!newTreated)
      setTreated("all");
    else
      setTreated(newTreated);
  };

  const handlePopoverOpenReason = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    popoverId: number
  ) => {
    setOpenedPopoverIdReason(popoverId);
    setAnchorElReason(event.currentTarget);
  };

  const handlePopoverCloseReason = () => {
    setOpenedPopoverIdReason(null);
    setAnchorElReason(null);
  };

  const handlePopoverOpenAdmin = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    popoverId: number
  ) => {
    setOpenedPopoverIdAdmin(popoverId);
    setAnchorElAdmin(event.currentTarget);
  };

  const handlePopoverCloseAdmin = () => {
    setOpenedPopoverIdAdmin(null);
    setAnchorElAdmin(null);
  };

  const handleClickOpen = (id: number) => {
    setAbuseIdToTreat(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAbuseIdToTreat(null);
    setAdminNote(null);
  };

  const handleTreat = () => {
    abuseIdToTreat && adminNote && treatAbuse(abuseIdToTreat, adminNote);
    setAbuseIdToTreat(null);
    setAdminNote(null);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={8}>
                <TextField
                  id="search-textfield"
                  label="Nom ou Prénom"
                  fullWidth
                  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                    if (e.key === "Enter") {
                      setAbusesDataSearchInput(searchInput, limit, { treated: treated });
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(event.currentTarget.value as string);
                  }}
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SearchIcon />}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    setAbusesDataSearchInput(searchInput, limit, { treated: treated });
                  }}
                >
                  Chercher
                </Button>
              </Grid>
              <Grid item xs>
                <ToggleButtonGroup
                  value={treated}
                  exclusive
                  size="small"
                  onChange={handleHandled}
                  aria-label="Filter handled reports"
                >
                  <ToggleButton
                    value="treated"
                    aria-label="handled"
                    style={{
                      borderRadius: "20px 0 0 20px",
                      borderColor: "#F3C557",
                    }}
                  >
                    Traité
                  </ToggleButton>
                  <ToggleButton
                    value="untreated"
                    aria-label="not handled"
                    style={{
                      borderRadius: "0 20px 20px 0",
                      borderColor: "#F3C557",
                    }}
                  >
                    Non traité
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Table aria-label="table des signalements" size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Lanceur d'alerte</TableCell>
                <TableCell>Utilisateur signalé</TableCell>
                <TableCell>Babysitting signalé</TableCell>
                <TableCell>Raison</TableCell>
                <TableCell>Traité</TableCell>
                <TableCell>Note des admins</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingAbusesData ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="h5" component="p">
                      Chargement...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : abusesData && abusesData.count > 0 ? (
                abusesData.data.map((abuse: any) => (
                  <TableRow key={abuse.id}>
                    <TableCell className={classes.tableCell}>
                      {abuse.id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {Moment(abuse.created_at).format("LLL")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {abuse.user_who_reported && <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/user/" + abuse.user_who_reported.id)
                        }
                      >
                        {abuse.user_who_reported.first_name}{" "}
                        {abuse.user_who_reported.last_name}
                        {" ("}
                        <Link
                          href={"/user/" + abuse.user_who_reported.id}
                          target="_blank"
                        >
                          {abuse.user_who_reported.id}
                        </Link>
                        {")"}
                        &ensp;
                        {abuse.user_who_reported.banned && (
                          <Box
                            color="white"
                            bgcolor="#F00"
                            paddingX="5px"
                            borderRadius="3px"
                            style={{ display: "inline-flex" }}
                          >
                            <Typography variant="caption" component="span">
                              <b>banni</b>
                            </Typography>
                          </Box>
                        )}
                      </span>}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {abuse.user_reported && <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/user/" + abuse.user_reported.id)
                        }
                      >
                        {abuse.user_reported.first_name}{" "}
                        {abuse.user_reported.last_name}
                        {" ("}
                        <Link
                          href={"/user/" + abuse.user_reported.id}
                          target="_blank"
                        >
                          {abuse.user_reported.id}
                        </Link>
                        {")"}
                        &ensp;
                        {abuse.user_reported.banned && (
                          <Box
                            color="white"
                            bgcolor="#F00"
                            paddingX="5px"
                            borderRadius="3px"
                            style={{ display: "inline-flex" }}
                          >
                            <Typography variant="caption" component="span">
                              <b>banni</b>
                            </Typography>
                          </Box>
                        )}
                      </span>}
                    </TableCell>
                    <TableCell>
                      {abuse.babysitting_reported && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              "/babysitting/" + abuse.babysitting_reported.id
                            )
                          }
                        >
                          {abuse.babysitting_reported.id}
                        </span>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {abuse.reason &&
                        (abuse.reason.length <= 50 ? (
                          <p>{abuse.reason}</p>
                        ) : (
                          <div>
                            <Typography>
                              {abuse.reason.substr(0, 50)}...
                              <Typography
                                aria-owns={
                                  openedPopoverIdReason === abuse.id
                                    ? "mouse-over-popover"
                                    : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(
                                  e: React.MouseEvent<HTMLElement, MouseEvent>
                                ) => handlePopoverOpenReason(e, abuse.id)}
                                onMouseLeave={handlePopoverCloseReason}
                                component="span"
                                style={{ color: "#2EB398" }}
                              >
                                {" "}
                                voir plus
                              </Typography>
                            </Typography>
                            <Popover
                              className={classes.popover}
                              classes={{
                                paper: classes.paper,
                              }}
                              open={openedPopoverIdReason === abuse.id}
                              anchorEl={anchorElReason}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                            >
                              <Typography>{abuse.reason}</Typography>
                            </Popover>
                          </div>
                        ))}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {abuse.treated_at ? (
                        <p>{Moment(abuse.treated_at).format("LLL")}</p>
                      ) : (
                        <span
                          style={{ color: "#7CD5BA", cursor: "pointer" }}
                          onClick={() => handleClickOpen(abuse.id)}
                        >
                          Traiter
                        </span>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {abuse.admin_note &&
                        (abuse.admin_note.length <= 50 ? (
                          <p>{abuse.admin_note}</p>
                        ) : (
                          <div>
                            <Typography>
                              {abuse.admin_note.substr(0, 50)}...
                              <Typography
                                aria-owns={
                                  openedPopoverIdAdmin === abuse.id
                                    ? "mouse-over-popover"
                                    : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(
                                  e: React.MouseEvent<HTMLElement, MouseEvent>
                                ) => handlePopoverOpenAdmin(e, abuse.id)}
                                onMouseLeave={handlePopoverCloseAdmin}
                                component="span"
                                style={{ color: "#2EB398" }}
                              >
                                {" "}
                                voir plus
                              </Typography>
                            </Typography>
                            <Popover
                              className={classes.popover}
                              classes={{
                                paper: classes.paper,
                              }}
                              open={openedPopoverIdAdmin === abuse.id}
                              anchorEl={anchorElAdmin}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                            >
                              <Typography>{abuse.admin_note}</Typography>
                            </Popover>
                          </div>
                        ))}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" component="p">
                      Aucun résultat trouvé
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          {abusesData && abusesData.total_count > limit && (
            <Button
              variant="text"
              color="primary"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setLimit(limit + 10);
              }}
              disabled={loadingAbusesData}
            >
              Voir plus
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Traitement du signalement {abuseIdToTreat}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ajoute une note pour décrire comment tu as traité le signalement.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="admin_note"
            label="Note"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAdminNote(event.currentTarget.value as string);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleTreat} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Abuses;
