import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";
import AvatarCard from "../avatarCard";
import BabysittingCard from "./babysittingCard";
import BabysittingApplicationsCard from "./babysittingApplicationsCard";
import NotificationsCard from "./notificationsCard";

const Babysitting = () => {
  const {
    loadingParentData,
    setParentId,
    loadingBabysitterData,
    setBabysitterId,
    babysittingData,
    loadingBabysittingData,
    setBabysittingId,
  } = useContext(ApiBbsContext);
  const navigate = useNavigate();
  const { babysittingId } = useParams<{ babysittingId: string }>();

  useEffect(() => {
    if (babysittingId) {
      setBabysittingId(Number(babysittingId));
    }
  }, [babysittingId]);

  return (
    <>
      {loadingBabysittingData ? (
        <h3>Chargement...</h3>
      ) : (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs style={{ flexGrow: 2.2 }}>
                  {loadingParentData ? (
                    <h3>Chargement...</h3>
                  ) : (
                    <AvatarCard type={"parent"} />
                  )}
                </Grid>
                <Grid item xs style={{ flexGrow: 5 }}>
                  <BabysittingCard />
                </Grid>
                {babysittingData.babysitter_id && (
                  <Grid item xs style={{ flexGrow: 2.2 }}>
                    {loadingBabysitterData ? (
                      <h3>Chargement...</h3>
                    ) : (
                      <AvatarCard type={"babysitter"} />
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BabysittingApplicationsCard />
            </Grid>
            <Grid item xs={12}>
              <NotificationsCard />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Babysitting;
