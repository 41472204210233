import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";
import getAddressIndex from "../../utils/getAddressIndex";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  header: {
    textAlign: "center",
    background: "#CCC",
    color: "#FFF",
  },
  media: {
    height: "auto",
    maxWidth: "25vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const AvatarCard = ({
  type,
}: {
  type: "profile" | "parent" | "babysitter";
}) => {
  const {
    profileData,
    loadingProfileData,
    parentData,
    loadingParentData,
    babysitterData,
    loadingBabysitterData,
  } = useContext(ApiBbsContext);
  const navigate = useNavigate();
  const classes = useStyles();

  const [addressIndex, setAddressIndex] = useState(-1);
  const [pictureIndex, setPictureIndex] = useState(0);

  useEffect(() => {
    if (
      type === "profile" &&
      profileData &&
      profileData.addresses &&
      profileData.addresses.data &&
      profileData.current_address_id
    ) {
      setAddressIndex(
        getAddressIndex(
          profileData.addresses.data,
          profileData.current_address_id
        )
      );
    }
  }, [profileData]);
  useEffect(() => {
    if (
      type === "parent" &&
      parentData &&
      parentData.addresses &&
      parentData.addresses.data &&
      parentData.current_address_id
    ) {
      setAddressIndex(
        getAddressIndex(
          parentData.addresses.data,
          parentData.current_address_id
        )
      );
    }
  }, [parentData]);
  useEffect(() => {
    if (
      type === "babysitter" &&
      babysitterData &&
      babysitterData.addresses &&
      babysitterData.addresses.data &&
      babysitterData.current_address_id
    ) {
      setAddressIndex(
        getAddressIndex(
          babysitterData.addresses.data,
          babysitterData.current_address_id
        )
      );
    }
  }, [babysitterData]);

  const nextPicture = (len: number) => {
    if (len > 1) {
      let new_index = 0;
      if (pictureIndex < len - 1) {
        new_index = pictureIndex + 1;
      } else new_index = 0;
      setPictureIndex(new_index);
    }
  };

  const getAge = (str: string) => {
    let today = new Date();
    let birthDate = new Date(str);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return <span> &bull; {age} ans</span>;
  };

  let data = profileData;
  let loadingData = loadingProfileData;
  let title = "Données personnelles";
  let color = "#CCC";
  if (type === "parent") {
    title = "Parent";
    data = parentData;
    loadingData = loadingParentData;
    color = "#79B0EC";
  } else if (type === "babysitter") {
    title = "Babysitter";
    data = babysitterData;
    loadingData = loadingBabysitterData;
    color = "#79B0EC";
  }

  return (
    <>
      {!loadingData && (
        <Card>
          <CardHeader
            className={classes.header}
            title={title}
            style={{ background: color }}
          />
          {data.pictures.count > 0 ? (
            <CardMedia
              className={classes.media}
              component="img"
              alt="Image de profil"
              image={data.pictures.data[pictureIndex].url}
              style={{
                cursor: data.pictures.count > 1 ? "pointer" : "default",
              }}
              onClick={() => nextPicture(data.pictures.count)}
              title="Image de profil"
            />
          ) : (
            data.default_picture_url && (
              <CardMedia
                className={classes.media}
                component="img"
                alt="Image de profil"
                image={data.default_picture_url}
                title="Image de profil"
              />
            )
          )}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              <span>
                <Link
                  style={{ cursor: "pointer" }}
                  color="inherit"
                  onClick={() => navigate("/user/" + data.id)}
                >
                  {data.first_name + " " + data.last_name}
                  {data.birthday && getAge(data.birthday)}
                </Link>
                {" ("}
                <Link href={"/user/" + data.id} target="_blank">
                  {data.id}
                </Link>
                {")"}
              </span>
            </Typography>
            {data.email && (
              <Typography variant="body2" color="textSecondary" component="p">
                {data.email}
              </Typography>
            )}
            {data.telephone && (
              <Typography variant="body2" color="textSecondary" component="p">
                {data.telephone}
              </Typography>
            )}
            {addressIndex >= 0 && (
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {data.addresses.data[addressIndex].google_formatted_address}
              </Typography>
            )}
            {data.description && (
              <Typography variant="body2" color="textSecondary" component="p">
                {data.description}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            {data.role && data.role.name === "babysitter" && (
              <Box
                color="white"
                bgcolor="#79B0EC"
                paddingX="5px"
                borderRadius="3px"
              >
                <Typography variant="caption" component="span">
                  <b>BABYSITTER</b>
                </Typography>
              </Box>
            )}
            {data.role && data.role.name === "parent" && (
              <Box
                color="white"
                bgcolor="#55C9A6"
                paddingX="5px"
                borderRadius="3px"
              >
                <Typography variant="caption" component="span">
                  <b>PARENT</b>
                </Typography>
              </Box>
            )}
            {data.is_subscribed && data.role && data.role.name === "parent" && (
              <Box
                color="white"
                bgcolor="#B4A1DD"
                paddingX="5px"
                borderRadius="3px"
              >
                <Typography variant="caption" component="span">
                  <b>ABONNÉ</b>
                </Typography>
              </Box>
            )}
            {data.accesses &&
              data.accesses.data &&
              data.accesses.data[0] &&
              data.accesses.data[0].level === "admin" && (
                <Box
                  color="white"
                  bgcolor="#000"
                  paddingX="5px"
                  borderRadius="3px"
                >
                  <Typography variant="caption" component="span">
                    <b>ADMIN</b>
                  </Typography>
                </Box>
              )}
            {data.banned && (
              <Box
                color="white"
                bgcolor="#F00"
                paddingX="5px"
                borderRadius="3px"
              >
                <Typography variant="caption" component="span">
                  <b>BANNED</b>
                </Typography>
              </Box>
            )}
            {data.credit_card && (
              <Box
                color="white"
                bgcolor="#79B0EC"
                paddingX="5px"
                borderRadius="3px"
              >
                <Typography variant="caption" component="span">
                  <b>CB</b>
                </Typography>
              </Box>
            )}
            {data.bank_account && (
              <Box
                color="white"
                bgcolor="#55C9A6"
                paddingX="5px"
                borderRadius="3px"
              >
                <Typography variant="caption" component="span">
                  <b>IBAN</b>
                </Typography>
              </Box>
            )}
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default AvatarCard;
