import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import axiosInstance from "../axios/axios";
import getAge from "../../utils/getAge";
import { auth } from "../firebase/firebase";

type apiBbsProps = {
  // user logged in to backoffice
  user: any;
  loadingUser: boolean;
  logout: () => void;
  // profile data
  profileData: any;
  events: any;
  loadingProfileData: boolean;
  loadingProfileEvents: boolean;
  setEventsLimit: (limit: number) => void;
  setUserId: (id: number) => void;
  drawerData: any;
  setDrawerDataRaw: (data: any, key: string | null) => void;
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  // users data
  usersData: any;
  loadingUsersData: boolean;
  setUsersSearchInput: (input: string) => void;
  // babysitting data
  babysittingData: any;
  loadingBabysittingData: boolean;
  setBabysittingId: (id: number) => void;
  parentData: any;
  loadingParentData: boolean;
  setParentId: (id: number) => void;
  babysitterData: any;
  loadingBabysitterData: boolean;
  setBabysitterId: (id: number) => void;
  babysittingApplicationsData: any;
  loadingBabysittingApplicationsData: boolean;
  setBabysittingApplicationsDataSearchInput: (
    input: string,
    limit: number,
    filters: { active: string },
    babysitting_id: string | null
  ) => void;
  notificationsData: any;
  loadingNotificationsData: boolean;
  setNotificationsDataLimit: (limit: number) => void;
  // babysittings
  babysittingsData: any;
  loadingBabysittingsData: boolean;
  setBabysittingsSearchInput: (input: string, limit: number, filters: any) => void;
  // reports
  abusesData: any;
  loadingAbusesData: boolean;
  setAbusesDataSearchInput: (
    input: string,
    limit: number,
    filters: { treated: string }
  ) => void;
  treatAbuse: (id: number, note: string) => void;
};

export const ApiBbsContext = createContext<apiBbsProps>({
  user: null,
  loadingUser: true,
  logout: () => {},
  profileData: null,
  events: null,
  loadingProfileData: true,
  loadingProfileEvents: true,
  setEventsLimit: () => {},
  setUserId: () => {},
  drawerData: null,
  setDrawerDataRaw: () => {},
  openDrawer: false,
  setOpenDrawer: () => {},
  usersData: null,
  loadingUsersData: true,
  setUsersSearchInput: () => {},
  babysittingData: null,
  loadingBabysittingData: true,
  setBabysittingId: () => {},
  parentData: null,
  loadingParentData: true,
  setParentId: () => {},
  babysitterData: null,
  loadingBabysitterData: true,
  setBabysitterId: () => {},
  babysittingApplicationsData: null,
  loadingBabysittingApplicationsData: true,
  setBabysittingApplicationsDataSearchInput: () => {},
  notificationsData: null,
  loadingNotificationsData: true,
  setNotificationsDataLimit: () => {},
  babysittingsData: null,
  loadingBabysittingsData: true,
  setBabysittingsSearchInput: () => {},
  abusesData: null,
  loadingAbusesData: true,
  setAbusesDataSearchInput: () => {},
  treatAbuse: () => {},
});

const ApiBbsProvider = (props: any) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<any>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const [profileData, setProfileData] = useState<any>(null);
  const [events, setEvents] = useState<any>(null);
  const [loadingProfileData, setLoadingProfileData] = useState(true);
  const [loadingProfileEvents, setLoadingProfileEvents] = useState(true);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [users, setUsers] = useState<any>(null);
  const [loadingUsersData, setLoadingUsersData] = useState(true);

  const [babysitting, setBabysitting] = useState<any>(null);
  const [loadingBabysittingData, setLoadingBabysittingData] = useState(true);

  const [parentData, setParentData] = useState<any>(null);
  const [loadingParentData, setLoadingParentData] = useState(true);
  const [babysitterData, setBabysitterData] = useState<any>(null);
  const [loadingBabysitterData, setLoadingBabysitterData] = useState(true);

  const [
    babysittingApplicationsData,
    setBabysittingApplicationsData,
  ] = useState<any>(null);
  const [
    loadingBabysittingApplicationsData,
    setLoadingBabysittingApplicationsData,
  ] = useState(true);

  const [notificationsData, setNotificationsData] = useState<any>(null);
  const [loadingNotificationsData, setLoadingNotificationsData] = useState(
    true
  );

  const [babysittingsData, setBabysittingsData] = useState<any>(null);
  const [loadingBabysittingsData, setLoadingBabysittingsData] = useState(
    true
  );

  const [abusesData, setAbusesData] = useState<any>(null);
  const [loadingAbusesData, setLoadingAbusesData] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      console.info("onAuthStateChanged called");
      if (userAuth) {
        userAuth?.getIdToken().then((token) => {
          let bodyFormData = new FormData();
          bodyFormData.append("firebase_token", token);
          axiosInstance({
            method: "post",
            url: "user/authWithFirebase?expand=accesses,role",
            data: bodyFormData,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }).then((response) => {
            if (response && response.data) {
              console.info(response.data);
              if (
                response.data.accesses.data.length > 0 &&
                response.data.accesses.data[0].level === "admin"
              )
                setUser(response.data);
            }
          });
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (user) setLoadingUser(false);
  }, [user]);

  const logout = () => {
    auth.signOut();
  };

  const profile_basic_expands = [
    "credit_card",
    "bank_account",
    "role",
    "accesses",
    "abuse_reported[5].user_reported",
    "given_reviews[5].user_reviewed",
    "reviews[5].user",
    "notifications[5].payload",
    "emails[5].content",
    "abuse_where_user_is_reported[5].user_who_reported",
    "payments[5].payee",
    "incomes[5].payer",
    "subscriptions.plan",
    "successful_payments",
    "successful_incomes",
  ];
  const profile_five_expands = [
    "favorite_babysitters",
    "favorite_parents",
    "godparents",
    "godchildren",
    "pending_godparents",
    "pending_godchildren",
    "contacts",
    "abuse_reported",
    "abuse_where_user_is_reported",
    "applications",
    "children",
    "babysitter_babysittings",
    "parent_babysittings",
    "devices",
    "emails",
    "facebook_likes",
    "sms",
    "friends",
  ];
  const profile_hundred_expands = ["pictures", "addresses"];

  const setUserId = (id: number) => {
    if (profileData && profileData.id === id) return;

    setLoadingProfileData(true);

    if (parentData && parentData.id === id) setProfileData(parentData);
    else if (babysitterData && babysitterData.id === id)
      setProfileData(babysitterData);
    else {
      setLoadingProfileData(true);

      const profileDataUrl =
        "user/" +
        id +
        "?expand=" +
        profile_basic_expands.join() +
        "," +
        profile_five_expands.join("[5],") +
        "[5]," +
        profile_hundred_expands.join("[100],") +
        "[100]";
      axiosInstance({
        method: "get",
        url: profileDataUrl,
      })
        .then((response) => {
          if (response && response.data) {
            console.info(response.data);
            setProfileData(response.data);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.error(error.response.status);
            if (error.response.status === 401) {
              navigate("/");
            }
          } else {
            console.error(error.message);
          }
        });
    }
  };

  useEffect(() => {
    if (profileData) {
      setLoadingProfileData(false);
      setEventsLimit(10);
    }
  }, [profileData]);

  const setEventsLimit = (limit: number) => {
    if (profileData && profileData.id) {
      setLoadingProfileEvents(true);

      let timelineUrl = "user/" + profileData.id + "/timeline";
      axiosInstance({
        method: "get",
        url: timelineUrl,
      })
        .then((response) => {
          if (response && response.data) {
            console.info(response.data);
            setEvents(response.data);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.error(error.response.status);
            if (error.response.status === 401) {
              navigate("/");
            }
          } else {
            console.error(error.message);
          }
        });
    }
  };

  useEffect(() => {
    if (events) setLoadingProfileEvents(false);
  }, [events]);

  const getName = (person: any) => {
    return person.first_name
      ? person.first_name + (person.last_name ? " " + person.last_name : "")
      : person.telephone
      ? person.telephone
      : person.email;
  };

  const getDrawerItem = (item: any, key: string | null) => {
    if (item.object === "child")
      return (
        <ListItem button key={item.id}>
          <ListItemIcon>
            <Avatar
              src={item.default_picture_url ? item.default_picture_url : ""}
            ></Avatar>
          </ListItemIcon>
          <ListItemText
            primary={getName(item) + " - " + (item.birthday && getAge(item))}
          />
        </ListItem>
      );

    if (item.object === "user")
      return (
        <ListItem
          button
          key={item.id}
          onClick={() => navigate("/user/" + item.id)}
        >
          <ListItemIcon>
            <Avatar
              src={item.default_picture_url ? item.default_picture_url : ""}
            ></Avatar>
          </ListItemIcon>
          <ListItemText primary={getName(item)} />
        </ListItem>
      );

    if (item.object === "babysitting_review" && key)
      return (
        <div key={item.id}>
          <ListItem
            button
            onClick={() => navigate("/user/" + item[key].id)}
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <ListItemIcon>
                <Avatar
                  src={
                    item[key].default_picture_url
                      ? item[key].default_picture_url
                      : ""
                  }
                ></Avatar>
              </ListItemIcon>
              <ListItemText
                primary={getName(item[key]) + " : " + item.score + " / 5"}
              />
            </div>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginTop: "0.3em",
              }}
            >
              {item.description && <small>« {item.description} »</small>}
            </div>
          </ListItem>
          <Divider />
        </div>
      );

    if (item.object === "contact")
      return (
        <ListItem button key={item.id}>
          <ListItemIcon>
            <Avatar src=""></Avatar>
          </ListItemIcon>
          <ListItemText primary={item.telephone || item.email} />
        </ListItem>
      );

    if (item.object === "charge" && key) {
      let amount = item.amount.toString();
      amount =
        (amount.substr(0, amount.length - 2) || "0") +
        "," +
        amount.substr(amount.length - 2, amount.length);
      let formatted_amount = amount + " €";
      if (item.currency && item.currency.toLowerCase() !== "eur") {
        if (item.currency.toLowerCase() === "gbp") {
          formatted_amount = "£" + amount;
        } else {
          formatted_amount = "$" + amount;
        }
      }
      return (
        <ListItem
          button
          key={item.id}
          onClick={() =>
            window.open(
              "https://dashboard.stripe.com/payments/" + item.stripe_id,
              "_blank"
            )
          }
        >
          <ListItemIcon>
            <Avatar
              src={
                item[key].default_picture_url
                  ? item[key].default_picture_url
                  : ""
              }
            ></Avatar>
          </ListItemIcon>
          <ListItemText
            primary={getName(item[key]) + " - " + formatted_amount}
          />
        </ListItem>
      );
    }

    if (item.object === "notification")
      return (
        <div key={item.id}>
          <ListItem button>
            <ListItemText primary={item.payload.body} />
          </ListItem>
          <Divider />
        </div>
      );

    if (item.object === "device")
      return (
        <div key={item.id}>
          <ListItem button>
            <ListItemText
              primary={
                item.manufacturer +
                " " +
                item.model +
                " - appli v" +
                item.app_version +
                (item.uninstalled_at ? " désinstallée" : " installée")
              }
            />
          </ListItem>
          <Divider />
        </div>
      );

    if (item.object === "sms")
      return (
        <div key={item.id}>
          <ListItem button>
            <ListItemText primary={item.message} />
          </ListItem>
          <Divider />
        </div>
      );

    if (item.object === "email")
      return (
        <div key={item.id}>
          <ListItem button>
            <ListItemText primary={item.content.subject} />
          </ListItem>
          <Divider />
        </div>
      );

    if (item.object === "abuse" && key)
      return (
        <ListItem
          button
          key={item.id}
          onClick={() => navigate("/user/" + item[key].id)}
        >
          <ListItemIcon>
            <Avatar
              src={
                item[key].default_picture_url
                  ? item[key].default_picture_url
                  : ""
              }
            ></Avatar>
          </ListItemIcon>
          <ListItemText primary={getName(item[key])} />
        </ListItem>
      );
  };

  const setDrawerDataRaw = (data: any, key: string | null) => {
    let formattedData = (
      <div style={{ width: "auto" }} onClick={() => setOpenDrawer(false)}>
        <List>{data.map((item: any) => getDrawerItem(item, key))}</List>
      </div>
    );
    setDrawerData(formattedData);
    setOpenDrawer(true);
  };

  const setUsersSearchInput = (input: string) => {
    setLoadingUsersData(true);

    let users_search_url =
      "/search?expand=role,addresses,devices,reviews,is_subscribed";
    if (input) users_search_url += "&q=" + input;
    axiosInstance({
      method: "get",
      url: users_search_url,
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setUsers(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (users) setLoadingUsersData(false);
  }, [users]);

  const babysitting_expands = [
    "parent.roles",
    "parent.accesses",
    "parent.pictures",
    "parent.credit_card",
    "parent.bank_account",
    "babysitter.roles",
    "babysitter.accesses",
    "babysitter.pictures",
    "babysitter.credit_card",
    "babysitter.bank_account",
    "babysitter_application",
    "start_address",
    "week_days",
    "applications[100].babysitter",
    "pay_days[500]",
    "children",
    "charge.credit_card",
    "charge.bank_account",
    "reviews",
    "notifications[500].payload",
    "notifications.receiver",
    "notifications.device",
  ];

  const setBabysittingId = (id: number) => {
    if (babysitting && babysitting.id === id) return;

    setLoadingBabysittingData(true);

    const babysitting_url =
      "babysitting/" + id + "?expand=" + babysitting_expands.join();
    axiosInstance({
      method: "get",
      url: babysitting_url,
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setBabysitting(response.data);
          response.data.parent_id &&
            setParentId(response.data.parent_id as number);
          response.data.babysitter_id &&
            setBabysitterId(response.data.babysitter_id as number);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (babysitting) {
      setLoadingBabysittingData(false);
    }
  }, [babysitting]);

  const setParentId = (id: number) => {
    if (parentData && parentData.id === id) return;

    setLoadingParentData(true);

    if (profileData && profileData.id === id) {
      setParentData(profileData);
      return;
    }

    const parentDataUrl =
      "user/" +
      id +
      "?expand=" +
      profile_basic_expands.join() +
      "," +
      profile_five_expands.join("[5],") +
      "[5]," +
      profile_hundred_expands.join("[100],") +
      "[100]";
    axiosInstance({
      method: "get",
      url: parentDataUrl,
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setParentData(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (parentData) setLoadingParentData(false);
  }, [parentData]);

  const setBabysitterId = (id: number) => {
    if (babysitterData && babysitterData.id === id) return;

    setLoadingBabysitterData(true);

    if (profileData && profileData.id === id) {
      setBabysitterData(profileData);
      return;
    }

    const babysitterDataUrl =
      "user/" +
      id +
      "?expand=" +
      profile_basic_expands.join() +
      "," +
      profile_five_expands.join("[5],") +
      "[5]," +
      profile_hundred_expands.join("[100],") +
      "[100]";
    axiosInstance({
      method: "get",
      url: babysitterDataUrl,
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setBabysitterData(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (babysitterData) setLoadingBabysitterData(false);
  }, [babysitterData]);

  const setBabysittingApplicationsDataSearchInput = (
    input: string,
    limit: number,
    { active = "all" }: { active: string },
    babysitting_id: string | null = null
  ) => {
    setLoadingBabysittingApplicationsData(true);

    let babysitting_applications_search_url =
      "/backoffice/babysitting_applications?expand=babysitting.parent,babysitter,babysitter_address,week_days";
    if (input) babysitting_applications_search_url += "&q=" + input;
    babysitting_applications_search_url += "&active=" + active;
    babysitting_applications_search_url += "&limit=" + limit;
    if (!babysitting_id && babysitting && babysitting.id)
      babysitting_applications_search_url +=
        "&babysitting_id=" + babysitting.id;
    axiosInstance({
      method: "get",
      url: babysitting_applications_search_url,
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setBabysittingApplicationsData(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (babysittingApplicationsData)
      setLoadingBabysittingApplicationsData(false);
  }, [babysittingApplicationsData]);

  const setNotificationsDataLimit = (limit: number) => {
    setLoadingNotificationsData(true);

    if (babysitting && babysitting.id && babysitting.created_at) {
      let notifications_url =
        "/babysitting/" +
        babysitting.id +
        "/notifications?from=" +
        babysitting.created_at +
        "&expand=context&limit=" +
        limit;

      axiosInstance({
        method: "get",
        url: notifications_url,
      })
        .then((response) => {
          if (response && response.data) {
            console.info(response.data);
            setNotificationsData(response.data);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.error(error.response.status);
            if (error.response.status === 401) {
              navigate("/");
            }
          } else {
            console.error(error.message);
          }
        });
    }
  };

  useEffect(() => {
    if (notificationsData) setLoadingNotificationsData(false);
  }, [notificationsData]);

  const babysittings_url = "/backoffice/babysittings/";
  const babysittings_future_url =
    babysittings_url +
    "future" +
    "?expand=parent,applications[0],babysitter,start_address,children,week_days";
  const babysittings_past_url =
    babysittings_url +
    "past" +
    "?expand=parent,applications[0],babysitter,start_address,children,week_days,reviews,charges";

  const setBabysittingsSearchInput = (
    input: string,
    limit: number,
    filters: any = {future: "future", booked: "all", price_unit: "all", archived: "all"}
  ) => {
    setLoadingBabysittingsData(true);

    let search_url =
      filters.future === "future" ? babysittings_future_url : babysittings_past_url;
    if (input) search_url += "&q=" + input;
    search_url += "&limit_day=3&limit_id=5";
    search_url += "&booked=" + filters.booked + "&price_unit=" + filters.price_unit + "&archived=" + filters.archived;
    axiosInstance({
      method: "get",
      url: search_url,
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.all_babysittings &&
          response.data.all_babysittings.data
        ) {
          console.info(response.data.all_babysittings.data);
          setBabysittingsData(response.data.all_babysittings.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (babysittingsData) setLoadingBabysittingsData(false);
  }, [babysittingsData]);

  const setAbusesDataSearchInput = (
    input: string,
    limit: number,
    { treated = "all" }: { treated: string }
  ) => {
    setLoadingAbusesData(true);

    let abuses_url =
      "/backoffice/abuses" +
      "?expand=user_who_reported,user_reported,babysitting_reported";
    if (input) abuses_url += "&q=" + input;
    abuses_url += "&treated=" + treated;
    abuses_url += "&limit=" + limit;

    axiosInstance({
      method: "get",
      url: abuses_url,
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setAbusesData(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (abusesData) setLoadingAbusesData(false);
  }, [abusesData]);

  const treatAbuse = (id: number, note: string) => {
    let treat_abuse_url = "/abuse/" + id + "/treat";
    let bodyFormData = new FormData();
    bodyFormData.append("admin_note", note);
    axiosInstance({
      method: "post",
      url: treat_abuse_url,
      data: bodyFormData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response && response.data) {
          console.info(response.data);
          setAbusesDataSearchInput("", 10, { treated: "all" });
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            navigate("/");
          }
        } else {
          console.error(error.message);
        }
      });
  };

  return (
    <ApiBbsContext.Provider
      value={{
        user: user,
        loadingUser: loadingUser,
        logout: logout,
        profileData: profileData,
        events: events,
        loadingProfileData: loadingProfileData,
        loadingProfileEvents: loadingProfileEvents,
        setEventsLimit: setEventsLimit,
        setUserId: setUserId,
        drawerData: drawerData,
        setDrawerDataRaw: setDrawerDataRaw,
        openDrawer: openDrawer,
        setOpenDrawer: setOpenDrawer,
        usersData: users,
        loadingUsersData: loadingUsersData,
        setUsersSearchInput: setUsersSearchInput,
        babysittingData: babysitting,
        loadingBabysittingData: loadingBabysittingData,
        setBabysittingId: setBabysittingId,
        parentData: parentData,
        loadingParentData: loadingParentData,
        setParentId: setParentId,
        babysitterData: babysitterData,
        loadingBabysitterData: loadingBabysitterData,
        setBabysitterId: setBabysitterId,
        babysittingApplicationsData: babysittingApplicationsData,
        loadingBabysittingApplicationsData: loadingBabysittingApplicationsData,
        setBabysittingApplicationsDataSearchInput: setBabysittingApplicationsDataSearchInput,
        notificationsData: notificationsData,
        loadingNotificationsData: loadingNotificationsData,
        setNotificationsDataLimit: setNotificationsDataLimit,
        babysittingsData: babysittingsData,
        loadingBabysittingsData: loadingBabysittingsData,
        setBabysittingsSearchInput: setBabysittingsSearchInput,
        abusesData: abusesData,
        loadingAbusesData: loadingAbusesData,
        setAbusesDataSearchInput: setAbusesDataSearchInput,
        treatAbuse: treatAbuse,
      }}
    >
      {props.children}
    </ApiBbsContext.Provider>
  );
};

export default ApiBbsProvider;
