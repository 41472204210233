import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import "moment/locale/fr";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Popover from "@material-ui/core/Popover";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#EBA068",
    color: "#FFF",
  },
  tableCell: {
    verticalAlign: "top",
  },
  paper: {
    padding: "20px",
    width: "40vw",
  },
  popover: {
    pointerEvents: "none",
  },
});

const BabysittingApplicationsCard = () => {
  const {
    babysittingApplicationsData,
    loadingBabysittingApplicationsData,
    setBabysittingApplicationsDataSearchInput,
  } = useContext(ApiBbsContext);
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const classes = useStyles();

  const [active, setActive] = useState("all");

  const [openedPopoverId, setOpenedPopoverId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setBabysittingApplicationsDataSearchInput(
        searchInput,
        limit,
        { active: active },
        null
      );
  }, [limit, active]);

  const handleActive = (
    event: React.MouseEvent<HTMLElement>,
    newActive: string
  ) => {
    if (!newActive)
      setActive("all");
    else
      setActive(newActive);
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    popoverId: number
  ) => {
    setOpenedPopoverId(popoverId);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const getStatus = (application: any): any => {
    if (application.approved_by_parent) {
      return (
        <Box
          color="white"
          bgcolor="#55C9A6"
          paddingX="5px"
          borderRadius="3px"
          style={{ display: "inline-flex" }}
        >
          <Typography variant="caption" component="span">
            <b>bookée</b>
          </Typography>
        </Box>
      );
    }

    if (application.active) {
      return (
        <Box
          color="white"
          bgcolor="#79B0EC"
          paddingX="5px"
          borderRadius="3px"
          style={{ display: "inline-flex" }}
        >
          <Typography variant="caption" component="span">
            <b>active</b>
          </Typography>
        </Box>
      );
    }

    if (application.need_to_confirm) {
      return (
        <Box
          color="white"
          bgcolor="#EBA068"
          paddingX="5px"
          borderRadius="3px"
          style={{ display: "inline-flex" }}
        >
          <Typography variant="caption" component="span">
            <b>à confirmer</b>
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        color="white"
        bgcolor="#CDCCC8"
        paddingX="5px"
        borderRadius="3px"
        style={{ display: "inline-flex" }}
      >
        <Typography variant="caption" component="span">
          <b>{application.inactive_reason}</b>
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Card>
        <CardHeader className={classes.header} title="Candidatures" />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <form>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={8}>
                    <TextField
                      id="search-textfield"
                      label="Nom, Prénom, ID, ..."
                      fullWidth
                      onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                        if (e.key === "Enter") {
                          setBabysittingApplicationsDataSearchInput(
                            searchInput,
                            limit,
                            { active: active },
                            null
                          );
                          e.preventDefault();
                        }
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setSearchInput(event.currentTarget.value as string);
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<SearchIcon />}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        setBabysittingApplicationsDataSearchInput(
                          searchInput,
                          limit,
                          { active: active },
                          null
                        );
                      }}
                    >
                      Chercher
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <ToggleButtonGroup
                      value={active}
                      exclusive
                      size="small"
                      onChange={handleActive}
                      aria-label="Filter active applications"
                    >
                      <ToggleButton
                        value="1"
                        aria-label="active"
                        style={{
                          borderRadius: "20px 0 0 20px",
                          borderColor: "#F3C557",
                        }}
                      >
                        Active
                      </ToggleButton>
                      <ToggleButton
                        value="0"
                        aria-label="inactive"
                        style={{
                          borderRadius: "0 20px 20px 0",
                          borderColor: "#F3C557",
                        }}
                      >
                        Inactive
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="table des utilisateurs" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Babysitter</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Jours candidatés</TableCell>
                    <TableCell>Statut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingBabysittingApplicationsData ? (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" component="p">
                          Chargement des candidatures...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : babysittingApplicationsData &&
                    babysittingApplicationsData.count > 0 ? (
                    babysittingApplicationsData.data.map((application: any) => (
                      <TableRow key={application.id}>
                        <TableCell className={classes.tableCell}>
                          {application.id}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {Moment(application.created_at).format("LLL")}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                "/user/" + application.babysitter.id
                              )
                            }
                          >
                            {application.babysitter.first_name}{" "}
                            {application.babysitter.last_name}
                            {" ("}
                            <Link
                              href={"/user/" + application.babysitter.id}
                              target="_blank"
                            >
                              {application.babysitter.id}
                            </Link>
                            {")"}
                            &ensp;
                            {application.babysitter.banned && (
                              <Box
                                color="white"
                                bgcolor="#F00"
                                paddingX="5px"
                                borderRadius="3px"
                                style={{ display: "inline-flex" }}
                              >
                                <Typography variant="caption" component="span">
                                  <b>banni</b>
                                </Typography>
                              </Box>
                            )}
                          </span>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {application.description &&
                            (application.description.length <= 50 ? (
                              <p>{application.description}</p>
                            ) : (
                              <div>
                                <Typography>
                                  {application.description.substr(0, 50)}...
                                  <Typography
                                    aria-owns={
                                      openedPopoverId === application.id
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={(
                                      e: React.MouseEvent<
                                        HTMLElement,
                                        MouseEvent
                                      >
                                    ) => handlePopoverOpen(e, application.id)}
                                    onMouseLeave={handlePopoverClose}
                                    component="span"
                                    style={{ color: "#2EB398" }}
                                  >
                                    {" "}
                                    voir plus
                                  </Typography>
                                </Typography>
                                <Popover
                                  className={classes.popover}
                                  classes={{
                                    paper: classes.paper,
                                  }}
                                  open={openedPopoverId === application.id}
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography>
                                    {application.description}
                                  </Typography>
                                </Popover>
                              </div>
                            ))}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {application.week_days &&
                          application.week_days.count > 0 ? (
                            application.week_days.data.map((week_day: any) => (
                              <p key={week_day.id} style={{ margin: 0 }}>
                                {Moment(week_day.local_number, "e").format(
                                  "dddd"
                                )}
                              </p>
                            ))
                          ) : (
                            <p>-</p>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <div>{getStatus(application)}</div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" component="p">
                          Aucun résultat trouvé
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
        {babysittingApplicationsData &&
          babysittingApplicationsData.total_count > limit && (
            <CardActions>
              <Button
                variant="text"
                color="primary"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  setLimit(limit + 10);
                }}
              >
                Voir plus
              </Button>
            </CardActions>
          )}
      </Card>
    </>
  );
};

export default BabysittingApplicationsCard;
