import { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import Sidebar from "../../components/sidebar";
import Users from "../../components/users";
import Profile from "../../components/profile";
import Babysitting from "../../components/babysitting";
import Babysittings from "../../components/babysittings";
import Applications from "../../components/applications";
import Abuses from "../../components/abuses";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    loading: {
      textAlign: "center",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  });

const useStyles = makeStyles(styles);

const Home = () => {
  const { user, loadingUser } = useContext(ApiBbsContext);
  const navigate = useNavigate();

  const classes = useStyles();

  useEffect(() => {
    if (!(loadingUser || user)) navigate("/login");
  }, [user, loadingUser]);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        {loadingUser ? (
          <h3 className={classes.loading}>Chargement...</h3>
        ) : (
        <Routes>
          {/* <Route path="ltr/*" loader={({ params }) => { return navigate(params["*"]!); }} />
          <Route path="rtl/*" loader={({ params }) => { return navigate(params["*"]!); }} /> */}
          <Route path={"user/:userId"} element={<Profile />} />
          <Route path="user" element={<Users />} />
          <Route path={"babysitting/:babysittingId"} element={<Babysitting />} />
          <Route path="babysitting" element={<Babysittings />} />
          <Route path="application" element={<Applications />} />
          <Route path="abuse" element={<Abuses />} />
        </Routes>
        )}
        {/*user && (
          <div>
            <div>Home</div>
            <div>{JSON.stringify(user, null, 2)}</div>
          </div>
        )*/}
      </main>
    </div>
  );
};

export default Home;
