import { initializeApp } from "firebase/app";
import { getAuth, FacebookAuthProvider, OAuthProvider } from 'firebase/auth';

import firebaseConfig from './config';

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();
export const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ prompt: 'select_account' });
export const appleProvider = new OAuthProvider('apple.com');