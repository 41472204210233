import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";
import Moment from "moment";
import "moment/locale/fr";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import getCurrency from "../../utils/getCurrency";

const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      verticalAlign: "top",
    },
    paper: {
      padding: "20px",
      width: "40vw",
    },
    popover: {
      pointerEvents: "none",
    },
  });

const useStyles = makeStyles(styles);

const Babysittings = () => {
  const {
    babysittingsData,
    loadingBabysittingsData,
    setBabysittingsSearchInput,
  } = useContext(ApiBbsContext);
  const [searchInput, setSearchInput] = useState("");
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const [future, setFuture] = useState("future");
  const [booked, setBooked] = useState("all");
  const [price_unit, setPriceUnit] = useState("all");
  const [archived, setArchived] = useState("all");

  const classes = useStyles();

  useEffect(() => {
    setBabysittingsSearchInput(searchInput, limit, {
      future: future,
      booked: booked,
      price_unit: price_unit,
      archived: archived,
    });
  }, [limit, future, booked, price_unit, archived]);

  const handleFuture = (
    event: React.MouseEvent<HTMLElement>,
    newFuture: string
  ) => {
    if (!newFuture) setFuture("future");
    else setFuture(newFuture);
  };

  const handleBooked = (
    event: React.MouseEvent<HTMLElement>,
    newBooked: string
  ) => {
    if (!newBooked) setBooked("all");
    else setBooked(newBooked);
  };

  const handlePrice = (
    event: React.MouseEvent<HTMLElement>,
    newPriceUnit: string
  ) => {
    if (!newPriceUnit) setPriceUnit("all");
    else setPriceUnit(newPriceUnit);
  };

  const handleArchived = (
    event: React.MouseEvent<HTMLElement>,
    newArchived: string
  ) => {
    if (!newArchived) setArchived("all");
    else setArchived(newArchived);
  };

  const getTags = (babysitting: any) => {
    let spans = [];
    if (babysitting.archived) {
      spans.push(
        <Box
          key="archived"
          color="white"
          bgcolor="#B8B6B0"
          paddingX="5px"
          borderRadius="3px"
        >
          <Typography variant="caption" component="span">
            <b>archivé</b>
          </Typography>
        </Box>
      );
    }
    if (babysitting.babysitter_id) {
      spans.push(
        <Box
          key="booked"
          color="white"
          bgcolor="#68BD80"
          paddingX="5px"
          borderRadius="3px"
        >
          <Typography variant="caption" component="span">
            <Link
              style={{ cursor: "pointer" }}
              color="inherit"
              onClick={() =>
                navigate("/user/" + babysitting.babysitter_id)
              }
            >
              <b>
                booké :{" "}
                {babysitting.babysitter.first_name +
                  " " +
                  babysitting.babysitter.last_name}
              </b>
            </Link>
          </Typography>
        </Box>
      );
    } else if (babysitting.applications.total_count > 0) {
      spans.push(
        <Box
          key="applications"
          color="white"
          bgcolor="#EA8564"
          paddingX="5px"
          borderRadius="3px"
        >
          <Typography variant="caption" component="span">
            <b>{babysitting.applications.total_count} candidats</b>
          </Typography>
        </Box>
      );
    }

    return <div style={{ display: "flex" }}>{spans}</div>;
  };

  const getCharge = (charges: any[]) => {
    if (charges.length > 0) {
      let charge = charges[0];
      let amount_formatted = getChargeFormattedAmount(charge);
      return (
        <Box color="white" bgcolor="#B4A1DD" paddingX="5px" borderRadius="3px">
          <Typography variant="caption" component="span">
            {charge.stripe_id ? (
              <Link
                style={{ cursor: "pointer" }}
                color="inherit"
                href={
                  "https://dashboard.stripe.com/payments/" + charge.stripe_id
                }
                target="_blank"
              >
                <b>{amount_formatted}</b>
              </Link>
            ) : (
              <b>{amount_formatted}</b>
            )}
          </Typography>
        </Box>
      );
    }
  };

  const getChargeFormattedAmount = (charge: any): string => {
    let paid_amount = (charge["amount"] + charge["stripe_fee"]).toString();
    paid_amount =
      paid_amount.substring(0, paid_amount.length - 2) +
      "." +
      paid_amount.substring(paid_amount.length - 2, paid_amount.length);
    switch (charge["currency"].toLowerCase()) {
      case "eur":
        return paid_amount + " €";
      case "gbp":
        return "£" + paid_amount;
      default:
        return "$" + paid_amount;
    }
  };

  const getUserReview = (reviews: any[], user_id: number) => {
    let arrayLength = reviews.length;
    for (let i = 0; i < arrayLength; i++) {
      let r = reviews[i];
      if (r["user_reviewed_id"] === user_id) {
        return Reviews(r["score"]);
      }
    }
  };

  const Reviews = (score: number) => {
    if (!score) {
      return;
    }
    let stars = [];
    let rounded_score = Math.floor(score);
    for (let i = 0; i < rounded_score; i++) {
      stars.push(<StarIcon key={i} fontSize="small" />);
    }
    if (score > rounded_score) {
      stars.push(<StarHalfIcon key="half" fontSize="small" />);
    }
    for (let i = 0; i < 5 - stars.length; i++) {
      stars.push(<StarBorderIcon key={5 - i} fontSize="small" />);
    }
    stars.push(<span key="hello">&ensp;</span>);
    return <div style={{ display: "flex", alignItems: "center" }}>{stars}</div>;
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={3}>
                <TextField
                  id="search-textfield"
                  label="Nom, Téléphone, Email, Adresse, ..."
                  fullWidth
                  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                    if (e.key === "Enter") {
                      setBabysittingsSearchInput(searchInput, limit, {
                        future: future,
                        booked: booked,
                        price_unit: price_unit,
                        archived: archived,
                      });
                      e.preventDefault();
                    }
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(event.currentTarget.value as string);
                  }}
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SearchIcon />}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    setBabysittingsSearchInput(searchInput, limit, {
                      future: future,
                      booked: booked,
                      price_unit: price_unit,
                      archived: archived,
                    });
                  }}
                >
                  Chercher
                </Button>
              </Grid>
              <Grid item xs>
                <ToggleButtonGroup
                  value={future}
                  exclusive
                  size="small"
                  onChange={handleFuture}
                  aria-label="Filter future babysittings"
                >
                  <ToggleButton
                    value="past"
                    aria-label="past"
                    style={{
                      borderRadius: "20px 0 0 20px",
                      borderColor: "#4A90E2",
                    }}
                  >
                    Passé
                  </ToggleButton>
                  <ToggleButton
                    value="future"
                    aria-label="future"
                    style={{
                      borderRadius: "0 20px 20px 0",
                      borderColor: "#4A90E2",
                    }}
                  >
                    Future
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs>
                <ToggleButtonGroup
                  value={booked}
                  exclusive
                  size="small"
                  onChange={handleBooked}
                  aria-label="Filter booked babysittings"
                >
                  <ToggleButton
                    value="booked"
                    aria-label="booked"
                    style={{
                      borderRadius: "20px 0 0 20px",
                      borderColor: "#68BD80",
                    }}
                  >
                    Booké
                  </ToggleButton>
                  <ToggleButton
                    value="unbooked"
                    aria-label="not booked"
                    style={{
                      borderRadius: "0 20px 20px 0",
                      borderColor: "#68BD80",
                    }}
                  >
                    Non booké
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs>
                <ToggleButtonGroup
                  value={price_unit}
                  exclusive
                  size="small"
                  onChange={handlePrice}
                  aria-label="Filter babysittings by price"
                >
                  <ToggleButton
                    value="per_hour"
                    aria-label="per hour"
                    style={{
                      borderRadius: "20px 0 0 20px",
                      borderColor: "#F3C557",
                    }}
                  >
                    €/H
                  </ToggleButton>
                  <ToggleButton
                    value="total"
                    aria-label="total"
                    style={{
                      borderRadius: "0 20px 20px 0",
                      borderColor: "#F3C557",
                    }}
                  >
                    Total
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs>
                <ToggleButtonGroup
                  value={archived}
                  exclusive
                  size="small"
                  onChange={handleArchived}
                  aria-label="Filter archived babysittings"
                >
                  <ToggleButton
                    value="archived"
                    aria-label="archived"
                    style={{
                      borderRadius: "20px 0 0 20px",
                      borderColor: "#B8B6B0",
                    }}
                  >
                    Archivé
                  </ToggleButton>
                  <ToggleButton
                    value="unarchived"
                    aria-label="not archived"
                    style={{
                      borderRadius: "0 20px 20px 0",
                      borderColor: "#B8B6B0",
                    }}
                  >
                    Non archivé
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {loadingBabysittingsData ? (
          <Typography variant="h5" component="p">
            Chargement...
          </Typography>
        ) : babysittingsData ? (
          babysittingsData.map((babysitting_day: any) => (
            <div key={babysitting_day.day} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  <b>
                    {Moment(babysitting_day.day).calendar({
                      sameDay: "[aujourd'hui]",
                      nextDay: "[demain]",
                      nextWeek: "dddd",
                      lastDay: "[hier]",
                      lastWeek: "dddd [dernier]",
                      sameElse: "DD/MM/YYYY",
                    })}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Table aria-label="table des babysittings" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Parent</TableCell>
                      <TableCell>Début</TableCell>
                      <TableCell>Fin</TableCell>
                      <TableCell>Jrs</TableCell>
                      <TableCell>Prix</TableCell>
                      <TableCell>Tags</TableCell>
                      <TableCell>Enfants</TableCell>
                      <TableCell>Adresse</TableCell>
                      {future === "past" && <TableCell>BS noté</TableCell>}
                      {future === "past" && <TableCell>PA noté</TableCell>}
                      {future === "past" && <TableCell>Payé</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {babysitting_day.babysittings &&
                    babysitting_day.babysittings.data &&
                    babysitting_day.babysittings.count > 0 ? (
                      babysitting_day.babysittings.data.map(
                        (babysitting: any) => (
                          <TableRow key={babysitting.id}>
                            <TableCell className={classes.tableCell}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate("/babysitting/" + babysitting.id)
                                }
                              >
                                {babysitting.id}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(
                                    "/user/" + babysitting.parent.id
                                  )
                                }
                              >
                                {babysitting.parent.first_name}{" "}
                                {babysitting.parent.last_name}
                              </span>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box
                                color="white"
                                bgcolor={
                                  babysitting.category_id !== 1
                                    ? "#79B0EC"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_start_time
                                        ).format("HH")
                                      ) < 10
                                    ? "#A0C7F2"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_start_time
                                        ).format("HH")
                                      ) < 14
                                    ? "#79B0EC"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_start_time
                                        ).format("HH")
                                      ) < 18
                                    ? "#4A90E2"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_start_time
                                        ).format("HH")
                                      ) < 22
                                    ? "#515E78"
                                    : "#4C5D67"
                                }
                                paddingX="5px"
                                borderRadius="3px"
                                style={{ display: "inherit" }}
                              >
                                <Typography variant="caption" component="span">
                                  <b>
                                    {babysitting.category_id === 1
                                      ? Moment(
                                          babysitting.local_start_time
                                        ).format("HH[h]mm")
                                      : Moment(
                                          babysitting.local_start_time
                                        ).format("DD MMMM")}
                                  </b>
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box
                                color="white"
                                bgcolor={
                                  babysitting.category_id !== 1
                                    ? "#79B0EC"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_end_time
                                        ).format("HH")
                                      ) < 10
                                    ? "#A0C7F2"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_end_time
                                        ).format("HH")
                                      ) < 14
                                    ? "#79B0EC"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_end_time
                                        ).format("HH")
                                      ) < 18
                                    ? "#4A90E2"
                                    : parseInt(
                                        Moment(
                                          babysitting.local_end_time
                                        ).format("HH")
                                      ) < 22
                                    ? "#515E78"
                                    : "#4C5D67"
                                }
                                paddingX="5px"
                                borderRadius="3px"
                                style={{ display: "inherit" }}
                              >
                                <Typography variant="caption" component="span">
                                  <b>
                                    {babysitting.category_id === 1
                                      ? Moment(
                                          babysitting.local_end_time
                                        ).format("HH[h]mm") +
                                        (Moment(
                                          babysitting.local_end_time
                                        ).diff(
                                          Moment(babysitting.local_start_time),
                                          "hours",
                                          true
                                        ) > 24
                                          ? " (+" +
                                            Moment(
                                              babysitting.local_end_time
                                            ).diff(
                                              Moment(
                                                babysitting.local_start_time
                                              ),
                                              "hours",
                                              true
                                            ) +
                                            "h)"
                                          : "")
                                      : Moment(
                                          babysitting.local_end_time
                                        ).format("DD MMMM")}
                                  </b>
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {babysitting.week_days &&
                              babysitting.week_days.count > 0 ? (
                                babysitting.week_days.data.map(
                                  (week_day: any) => (
                                    <p key={week_day.id} style={{ margin: 0 }}>
                                      {Moment(
                                        week_day.local_number,
                                        "e"
                                      ).format("dddd")}
                                    </p>
                                  )
                                )
                              ) : (
                                <p style={{ margin: 0 }}>-</p>
                              )}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box
                                color="white"
                                bgcolor="#F3C557"
                                paddingX="5px"
                                borderRadius="3px"
                                style={{ display: "inherit" }}
                              >
                                <Typography variant="caption" component="span">
                                  <b>
                                    {babysitting.price / 100}{" "}
                                    {getCurrency(babysitting)}
                                    {babysitting.price_unit === "per_hour"
                                      ? "/h"
                                      : ""}
                                  </b>
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {getTags(babysitting)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {babysitting.children.total_count}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {
                                babysitting.start_address
                                  .google_formatted_address
                              }
                            </TableCell>
                            {future === "past" && (
                              <TableCell className={classes.tableCell}>
                                {babysitting.reviews &&
                                babysitting.reviews.data &&
                                babysitting.babysitter_id
                                  ? getUserReview(
                                      babysitting.reviews.data,
                                      babysitting.babysitter_id
                                    )
                                  : "-"}
                              </TableCell>
                            )}
                            {future === "past" && (
                              <TableCell className={classes.tableCell}>
                                {babysitting.reviews &&
                                babysitting.reviews.data &&
                                babysitting.babysitter_id
                                  ? getUserReview(
                                      babysitting.reviews.data,
                                      babysitting.parent_id
                                    )
                                  : "-"}
                              </TableCell>
                            )}
                            {future === "past" && (
                              <TableCell className={classes.tableCell}>
                                {babysitting.charges && babysitting.charges.data
                                  ? getCharge(babysitting.charges.data)
                                  : "-"}
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6" component="p">
                            Aucun résultat trouvé
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                {babysittingsData && babysittingsData.total_count > limit && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      setLimit(limit + 10);
                    }}
                    disabled={loadingBabysittingsData}
                  >
                    Voir plus
                  </Button>
                )}
              </Grid>
            </div>
          ))
        ) : (
          <Typography variant="h6" component="p">
            Aucun résultat trouvé
          </Typography>
        )}
      </Grid>
    </div>
  );
};

export default Babysittings;
