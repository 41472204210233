import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Map from "../../map";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import getAddressIndex from "../../../utils/getAddressIndex";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  header: {
    textAlign: "center",
    background: "#CCC",
    color: "#FFF"
  },
  media: {
    height: "auto",
  },
});

const MapCard = () => {
  const { profileData } = useContext(ApiBbsContext);
  const classes = useStyles();

  type locationProps = {
    address: string;
      lat: number;
      lng: number;
  };
  const [location, setLocation] = useState<locationProps | null>(null);
  const [addressIndex, setAddressIndex] = useState(-1);

  useEffect(() => {
    if (
      profileData &&
      profileData.addresses &&
      profileData.addresses.data &&
      profileData.current_address_id
    ) {
      setAddressIndex(
        getAddressIndex(
          profileData.addresses.data,
          profileData.current_address_id
        )
      );
    }
  }, [profileData]);

  useEffect(() => {
    if (addressIndex >= 0) {
      setLocation({
        address:
          profileData.addresses.data[addressIndex].google_formatted_address,
          lat: profileData.addresses.data[addressIndex].location_lat,
          lng: profileData.addresses.data[addressIndex].location_lng,
      });
    }
  }, [addressIndex]);

  const previousAddress = () => {
    if (addressIndex > 0) {
      setAddressIndex(addressIndex - 1);
    }
  };

  const nextAddress = () => {
    if (addressIndex < profileData.addresses.data.length - 1) {
      setAddressIndex(addressIndex + 1);
    }
  };

  return (
    location && (
      <Card
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CardHeader className={classes.header} title={profileData.addresses.data.length > 1 ? "Adresses" : "Adresse"} />
        <CardMedia style={{ flexGrow: 1, minHeight: "200px" }}>
          <Map location={location} zoomLevel={17}></Map>
        </CardMedia>
        {profileData.addresses.data.length > 1 && (
          <CardActions>
            {addressIndex > 0 && (
              <Button
                size="small"
                style={{ marginRight: "auto" }}
                onClick={previousAddress}
              >
                Précédente
              </Button>
            )}
            {addressIndex < profileData.addresses.data.length - 1 && (
              <Button
                size="small"
                style={{ marginLeft: "auto" }}
                onClick={nextAddress}
              >
                Suivante
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    )
  );
};

export default MapCard;
