import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import ApiBbsProvider from "../../utils/providers/apiBbsProvider";

import Login from "../../routes/login";
import Home from "../../routes/home";

import "./app.css";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#0073AC",
          },
          secondary: {
            main: "#F5A623",
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <ApiBbsProvider>
          <CssBaseline />
          <Routes>
            <Route path="/*" element={<Home/>} />
            <Route path="/login" element={<Login/>} />
          </Routes>
        </ApiBbsProvider>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
