const getAgeInYears = (str: string): number => {
  let today = new Date();
  let birthDate = new Date(str);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getAgeInMonths = (str: string): number => {
  let today = new Date();
  let birthDate = new Date(str);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  return m + 12*age;
};

export default function getAge(u: any): string {
  const age = getAgeInYears(u.birthday);
  let age_str = age.toString();
  if (age < 2) {
    age_str = getAgeInMonths(u.birthday) + ' mois'
  }
  else {
    age_str += ' ans'
  }
  return age_str;
}
