import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EuroIcon from '@material-ui/icons/Euro';

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#B4A1DD",
    color: "#FFF"
  },
});

const PaymentCard = () => {
  const { profileData, setDrawerDataRaw } = useContext(ApiBbsContext);
  const classes = useStyles();

  const formatSubscription = (sub: any) => {
    let result = sub.plan.description;
    switch (sub.status) {
      case 'ended':
        return result + " (expiré)";
      case 'canceled':
        return result + " (annulé)";
      case 'paused':
        return  result + " (en pause)";
      case 'active':
        return result
    }
  };

  return (
    profileData && (
      <Card>
        <CardHeader className={classes.header} title="Paiements" />
        <CardContent>
          <List dense={true}>
          {profileData.subscriptions.count > 0 && <ListItem>
              <ListItemIcon>
                <SubscriptionsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary" component="p">
                <Link
                      href={'https://dashboard.stripe.com/subscriptions/' + profileData.subscriptions.data[0].stripe_id}
                      target="_blank"
                      color="inherit"
                    >
                      {formatSubscription(profileData.subscriptions.data[0])}
                    </Link>
                </Typography>
              </ListItemText>
            </ListItem>}
            {profileData.stripe_customer_id && <ListItem>
              <ListItemIcon>
                <CreditCardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary" component="p">
                <Link
                      href={'https://dashboard.stripe.com/customers/' + profileData.stripe_customer_id}
                      target="_blank"
                      color="inherit"
                    >
                      Carte de crédit ajoutée
                    </Link>
                </Typography>
              </ListItemText>
            </ListItem>}
            {profileData.stripe_account_id && <ListItem>
              <ListItemIcon>
                <AccountBalanceIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary" component="p">
                <Link
                      href={'https://dashboard.stripe.com/applications/users/' + profileData.stripe_account_id}
                      target="_blank"
                      color="inherit"
                    >
                      IBAN enregistré
                    </Link>
                </Typography>
              </ListItemText>
            </ListItem>}
            {profileData.role.name === "babysitter" && (
              <ListItem>
                <ListItemIcon>
                  <EuroIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      cursor:
                        profileData.successful_incomes > 0
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() =>
                      profileData.successful_incomes > 0 &&
                      setDrawerDataRaw(profileData.incomes.data, "payer")
                    }
                  >
                    {profileData.successful_incomes + (profileData.successful_incomes > 1
                        ? " paiements reçus"
                        : " paiement reçu")}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {profileData.role.name === "parent" && (
              <ListItem>
                <ListItemIcon>
                  <EuroIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      cursor:
                        profileData.successful_payments > 0
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() =>
                      profileData.successful_payments > 0 &&
                      setDrawerDataRaw(profileData.payments.data, "payee")
                    }
                  >
                    {profileData.successful_payments + (profileData.successful_payments > 1
                        ? " paiements effectués"
                        : " paiement effectué")}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    )
  );
};

export default PaymentCard;
