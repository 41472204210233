import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import Moment from "moment";
import "moment/locale/fr";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import MapIcon from "@material-ui/icons/Map";
import SmsIcon from "@material-ui/icons/Sms";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import DevicesIcon from "@material-ui/icons/Devices";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PaymentIcon from "@material-ui/icons/Payment";
import EmailIcon from "@material-ui/icons/Email";
import ReportIcon from "@material-ui/icons/Report";
import ChildCareIcon from "@material-ui/icons/ChildCare";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#515E78",
    color: "#FFF",
  },
  oppositeContent: {
    flex: 0,
    padding: 0,
  },
  paper: {
    padding: "6px 16px",
  },
});

const TimelineCard = () => {
  const { events, loadingProfileEvents, setEventsLimit } = useContext(
    ApiBbsContext
  );
  const navigate = useNavigate();
  const classes = useStyles();

  const [limit, setLimit] = useState(10);

  useEffect(() => {
    limit > 10 && setEventsLimit(limit);
  }, [limit]);

  const getIconColor = (obj: any) => {
    if (obj === "babysitting_review") {
      return "bg-yellow";
    }

    if (obj === "address") {
      return "bg-red";
    }

    if (obj === "sms") {
      return "bg-darkorange";
    }

    if (obj === "notification") {
      return "bg-darkorange";
    }

    if (obj === "device") {
      return "bg-darkorange";
    }

    if (obj === "booked") {
      return "bg-blue";
    }

    if (obj === "charge") {
      return "bg-darkgreen";
    }

    if (obj === "email") {
      return "bg-darkorange";
    }

    if (obj === "abuse") {
      return "bg-red";
    }

    if (obj === "babysitting") {
      return "bg-desaturateddarkblue";
    }
  };

  const getIcon = (obj: any) => {
    if (obj === "babysitting_review") {
      return <StarIcon />;
    }

    if (obj === "address") {
      return <MapIcon />;
    }

    if (obj === "sms") {
      return <SmsIcon />;
    }

    if (obj === "notification") {
      return <NotificationsActiveIcon />;
    }

    if (obj === "device") {
      return <DevicesIcon />;
    }

    if (obj === "booked") {
      return <PlaylistAddCheckIcon />;
    }

    if (obj === "charge") {
      return <PaymentIcon />;
    }

    if (obj === "email") {
      return <EmailIcon />;
    }

    if (obj === "abuse") {
      return <ReportIcon />;
    }

    if (obj === "babysitting") {
      return <ChildCareIcon />;
    }
  };

  return (
    <>
      <Card>
        <CardHeader className={classes.header} title="Derniers évènements" />
        <CardContent>
          {loadingProfileEvents ? (
            <Typography variant="h6" component="p">
              Chargement des évènements...
            </Typography>
          ) : events && events.count > 0 ? (
            <Timeline align="left">
              {events.data &&
                events.data.map((event: any) => (
                  <TimelineItem key={event.id}>
                    <TimelineOppositeContent
                      className={classes.oppositeContent}
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{
                          backgroundColor:
                            getIconColor(event.object) === "bg-yellow"
                              ? "#FADD7F"
                              : getIconColor(event.object) === "bg-red"
                              ? "#EA7882"
                              : getIconColor(event.object) === "bg-darkorange"
                              ? "#EBA068"
                              : getIconColor(event.object) === "bg-blue"
                              ? "#79B0EC"
                              : getIconColor(event.object) === "bg-darkgreen"
                              ? "#306C67"
                              : getIconColor(event.object) ===
                                "bg-desaturateddarkblue"
                              ? "#515E78"
                              : "",
                        }}
                      >
                        {getIcon(event.object)}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography
                          variant="body2"
                          component="span"
                          style={{
                            display: "flex",
                          }}
                        >
                          {event.title}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                            style={{
                              display: "flex",
                              marginLeft: "auto",
                            }}
                          >
                            {Moment(event.created_at).format("LLL")} (UTC)
                          </Typography>
                        </Typography>
                        {event.body !== null && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {event.body}
                          </Typography>
                        )}
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          ) : (
            <Typography variant="h5" component="p">
              Aucune notification
            </Typography>
          )}
        </CardContent>
        {events && events.total_count > limit && (
          <CardActions>
            <Button
              variant="text"
              color="primary"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setLimit(limit + 10);
              }}
            >
              Voir plus
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default TimelineCard;
