import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarIcon from "@material-ui/icons/Star";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import PeopleIcon from "@material-ui/icons/People";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#79B0EC",
    color: "#FFF",
  },
});

const RelationCard = () => {
  const { profileData, setDrawerDataRaw } = useContext(ApiBbsContext);
  const classes = useStyles();

  return (
    profileData && (
      <Card>
        <CardHeader className={classes.header} title="Relations" />
        <CardContent>
          <List dense={true}>
            {profileData.role.name === "parent" && (
              <ListItem>
                <ListItemIcon>
                  <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      cursor:
                        profileData.favorite_babysitters.total_count > 0
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() =>
                      profileData.favorite_babysitters.total_count > 0 &&
                      setDrawerDataRaw(
                        profileData.favorite_babysitters.data,
                        null
                      )
                    }
                  >
                    {profileData.favorite_babysitters.total_count +
                      (profileData.favorite_babysitters.total_count > 1
                        ? " babysitters favoris"
                        : " babysitter favori")}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {profileData.role.name === "babysitter" && (
              <ListItem>
                <ListItemIcon>
                  <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      cursor:
                        profileData.favorite_parents.total_count > 0
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() =>
                      profileData.favorite_parents.total_count > 0 &&
                      setDrawerDataRaw(profileData.favorite_parents.data, null)
                    }
                  >
                    {profileData.favorite_parents.total_count +
                      (profileData.favorite_parents.total_count > 1
                        ? " parents favoris"
                        : " parent favori")}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {profileData.role.name === "parent" && (
              <ListItem>
                <ListItemIcon>
                  <ChildCareIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{
                      cursor:
                        profileData.children.total_count > 0
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() =>
                      profileData.children.total_count > 0 &&
                      setDrawerDataRaw(profileData.children.data, null)
                    }
                  >
                    {profileData.children.total_count +
                      (profileData.children.total_count > 1
                        ? " enfants"
                        : " enfant")}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.friends.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.friends.total_count > 0 &&
                    setDrawerDataRaw(profileData.friends.data, null)
                  }
                >
                  {profileData.friends.total_count +
                    (profileData.friends.total_count > 1 ? " amis" : " ami")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.godparents.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.godparents.total_count > 0 &&
                    setDrawerDataRaw(profileData.godparents.data, null)
                  }
                >
                  {profileData.godparents.total_count +
                    (profileData.godparents.total_count > 1
                      ? " parrains"
                      : " parrain")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.pending_godparents.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.pending_godparents.total_count > 0 &&
                    setDrawerDataRaw(profileData.pending_godparents.data, null)
                  }
                >
                  {profileData.pending_godparents.total_count +
                    (profileData.pending_godparents.total_count > 1
                      ? " parrains en attente"
                      : " parrain en attente")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.godchildren.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.godchildren.total_count > 0 &&
                    setDrawerDataRaw(profileData.godchildren.data, null)
                  }
                >
                  {profileData.godchildren.total_count +
                    (profileData.godchildren.total_count > 1
                      ? " filleuls"
                      : " filleul")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.pending_godchildren.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.pending_godchildren.total_count > 0 &&
                    setDrawerDataRaw(profileData.pending_godchildren.data, null)
                  }
                >
                  {profileData.pending_godchildren.total_count +
                    (profileData.pending_godchildren.total_count > 1
                      ? " filleuls en attente"
                      : " filleul en attente")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.contacts.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.contacts.total_count > 0 &&
                    setDrawerDataRaw(profileData.contacts.data, null)
                  }
                >
                  {profileData.contacts.total_count +
                    (profileData.contacts.total_count > 1
                      ? " contacts (carnet d'adresse)"
                      : " contact (carnet d'adresse)")}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )
  );
};

export default RelationCard;
