import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ReportIcon from "@material-ui/icons/Report";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#EA7882",
    color: "#FFF",
  },
});

const AbuseCard = () => {
  const { profileData, setDrawerDataRaw } = useContext(ApiBbsContext);
  const classes = useStyles();

  return (
    profileData && (
      <Card>
        <CardHeader className={classes.header} title="Abus" />
        <CardContent>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <NotificationImportantIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.abuse_reported.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.abuse_reported.total_count > 0 &&
                    setDrawerDataRaw(
                      profileData.abuse_reported.data,
                      "user_reported"
                    )
                  }
                >
                  {profileData.abuse_reported.total_count +
                    (profileData.abuse_reported.total_count > 1
                      ? " abus signalés"
                      : " abus signalé")}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ReportIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    cursor:
                      profileData.abuse_where_user_is_reported.total_count > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    profileData.abuse_where_user_is_reported.total_count > 0 &&
                    setDrawerDataRaw(
                      profileData.abuse_where_user_is_reported.data,
                      "user_who_reported"
                    )
                  }
                >
                  {"Profil signalé " +
                    profileData.abuse_where_user_is_reported.total_count +
                    " fois"}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    )
  );
};

export default AbuseCard;
