import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import "moment/locale/fr";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import TodayIcon from "@material-ui/icons/Today";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import FacebookIcon from "@material-ui/icons/Facebook";
import ShareIcon from "@material-ui/icons/Share";
import SchoolIcon from "@material-ui/icons/School";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#CCC",
    color: "#FFF",
  },
});

const InformationCard = () => {
  const { profileData } = useContext(ApiBbsContext);
  const classes = useStyles();

  return (
    profileData && (
      <Card style={{ flexGrow: 1 }}>
        <CardHeader className={classes.header} title="Informations" />
        <CardContent>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <PermIdentityIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary" component="p">
                  ID : {profileData.id}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TodayIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary" component="p">
                  Dernière activité :{" "}
                  {Moment(profileData.last_activity_at).format("LLL")} (UTC)
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CardMembershipIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" color="textSecondary" component="p">
                  Membre depuis le :{" "}
                  {Moment(profileData.created_at).format("LLL")} (UTC)
                </Typography>
              </ListItemText>
            </ListItem>
            {profileData.facebook_id && (
              <ListItem>
                <ListItemIcon>
                  <FacebookIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    <Link
                      href={
                        "https://www.facebook.com/search/top/?q=" +
                        profileData.first_name +
                        " " +
                        profileData.last_name
                      }
                      color="inherit"
                    >
                      {profileData.facebook_friends_total_count
                        ? profileData.facebook_friends_total_count +
                          " amis Facebook"
                        : "Profil Facebook"}
                    </Link>
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {profileData.gens_de_confiance_url && (
              <ListItem>
                <ListItemIcon>
                  <ShareIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    <Link
                      href={profileData.gens_de_confiance_url}
                      color="inherit"
                    >
                      {profileData.gens_de_confiance_nb_referrers +
                        " parrains GensDeConfiance"}
                    </Link>
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
            {profileData.education && (
              <ListItem>
                <ListItemIcon>
                  <SchoolIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {profileData.education}
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    )
  );
};

export default InformationCard;
