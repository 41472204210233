import axios from "axios";

const baseURL = "https://api.bbst.eu/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "X-Api-Version": "1.0.0",
  },
  withCredentials: true,
});

export default axiosInstance;