import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Moment from "moment";
import "moment/locale/fr";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { ApiBbsContext } from "../../../utils/providers/apiBbsProvider";
import getCurrency from "../../../utils/getCurrency";
import getAge from "../../../utils/getAge";

const useStyles = makeStyles({
  header: {
    textAlign: "center",
    background: "#55C9A6",
    color: "#FFF",
  },
  media: {
    maxHeight: "30vh",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const BabysittingCard = () => {
  const { babysittingData, loadingBabysittingData } = useContext(ApiBbsContext);
  const navigate = useNavigate();
  const classes = useStyles();

  const categoryElement = (category_id: number) => {
    switch (category_id) {
      case 1:
        return "PONCTUEL";
      case 2:
        return "RECURRENT";
      case 3:
        return "VACANCES";
    }
  };

  const formatChildren = (babysittingData: any) => {
    let children_data = babysittingData.children.data;
    let arrayLength = children_data.length;
    let children = [];
    for (let i = 0; i < arrayLength; i++) {
      let c = children_data[i];
      let age = getAge(c);
      if (c.first_name) {
        children.push(c.first_name + " (" + age + ")");
      } else {
        children.push("un enfant de " + age);
      }
    }
    return children.join(", ");
  };

  return (
    <>
      {!loadingBabysittingData && (
        <Card>
          <CardHeader
            className={classes.header}
            title="Données du babysitting"
          />
          {babysittingData.default_picture_url && (
            <CardMedia
              className={classes.media}
              component="img"
              alt="Image du babysitting"
              image={babysittingData.default_picture_url}
              title="Image du babysitting"
            />
          )}
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                      style={{ marginRight: "10px" }}
                    >
                      <b>Prix : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      {babysittingData.price / 100}{" "}
                      {getCurrency(babysittingData)}
                      {babysittingData.price_unit === "per_hour" ? "/h" : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                      style={{ marginRight: "10px" }}
                    >
                      <b>Début : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      {Moment(babysittingData.local_start_time).format(
                        "dddd D MMMM YYYY"
                      )}{" "}
                      {babysittingData.category_id === 1 &&
                        "à " +
                          Moment(babysittingData.local_start_time).format("LT")}
                    </Typography>
                    {babysittingData.category_id === 2 && (
                      <>
                        <p style={{ margin: "0.2em" }}>
                          <b>Jours de la semaine : </b>
                        </p>
                        {babysittingData.week_days.data.map((wd: any) => (
                          <p
                            key={wd.id}
                            style={{ margin: 0, marginLeft: "1em" }}
                          >
                            {Moment(wd.local_number, "e").format("dddd")} de{" "}
                            {Moment(wd.local_start_time, "hh:mm").format("LT")}{" "}
                            à {Moment(wd.local_end_time, "hh:mm").format("LT")}
                          </p>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                      style={{ marginRight: "10px" }}
                    >
                      <b>Fin : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      {Moment(babysittingData.local_end_time).format(
                        "dddd D MMMM YYYY"
                      )}{" "}
                      {babysittingData.category_id === 1 &&
                        "à " +
                          Moment(babysittingData.local_end_time).format("LT")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                      style={{ marginRight: "10px" }}
                    >
                      <b>Enfants : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      {formatChildren(babysittingData)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                      style={{ marginRight: "10px" }}
                    >
                      <b>Adresse : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      component="p"
                    >
                      {babysittingData.start_address.google_formatted_address}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {babysittingData.babysitter_id && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="p"
                        style={{ marginRight: "10px" }}
                      >
                        <b>Babysitter : </b>
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="span"
                        style={{ display: "inline-block", cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            "/user/" + babysittingData.babysitter_id
                          )
                        }
                      >
                        {babysittingData.babysitter.first_name}{" "}
                        {babysittingData.babysitter.last_name}{" "}
                      </Typography>
                      {babysittingData.babysitter_booked_at && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="span"
                          style={{ display: "inline-block" }}
                        >
                          &ensp; booké(e) le{" "}
                          {Moment(babysittingData.babysitter_booked_at).format(
                            "LLL"
                          )}{" "}
                          (UTC)
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {babysittingData.description && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="p"
                        style={{ marginRight: "10px" }}
                      >
                        <b>Description : </b>
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="p"
                      >
                        {babysittingData.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                  <b>Visible pour tout le monde à partir du : </b>
                  {Moment(babysittingData.public_at).format("LLL")} (UTC)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" component="p">
                  <b>Invisible pour tout le monde à partir du : </b>
                  {Moment(babysittingData.public_until).format("LLL")} (UTC)
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          {(babysittingData.category_id || babysittingData.archived) && (
            <CardActions>
              {babysittingData.category_id && (
                <Box
                  color="white"
                  bgcolor="#003875"
                  paddingX="5px"
                  borderRadius="3px"
                >
                  <Typography variant="caption" component="span">
                    <b>{categoryElement(babysittingData.category_id)}</b>
                  </Typography>
                </Box>
              )}
              {babysittingData.archived && (
                <Box
                  color="white"
                  bgcolor="#B8B6B0"
                  paddingX="5px"
                  borderRadius="3px"
                >
                  <Typography variant="caption" component="span">
                    <b>ARCHIVÉ</b>
                  </Typography>
                </Box>
              )}
            </CardActions>
          )}
        </Card>
      )}
    </>
  );
};

export default BabysittingCard;
