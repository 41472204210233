import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import GroupIcon from "@material-ui/icons/Group";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ReportIcon from "@material-ui/icons/Report";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { ApiBbsContext } from "../../utils/providers/apiBbsProvider";

const drawerWidth = "300px";

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  });

const useStyles = makeStyles(styles);

const Sidebar = () => {
  const navigate = useNavigate();

  const { user, logout } = useContext(ApiBbsContext);

  const classes = useStyles();
  const [openBabysittingsListItem, setOpenBabysittingsListItem] = useState(
    true
  );

  const handleClickUsers = () => {
    navigate("/user");
  };

  const handleClickBabysittings = () => {
    navigate("/babysitting");
  };

  const handleClickApplications = () => {
    navigate("/application");
  };

  const handleClickAbuses = () => {
    navigate("/abuse");
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="sidebar-subheader">
            Pages
          </ListSubheader>
        }
      >
        <ListItem button onClick={handleClickUsers}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Utilisateurs" />
        </ListItem>
        <ListItem button onClick={handleClickBabysittings}>
          <ListItemIcon>
            <ChildFriendlyIcon />
          </ListItemIcon>
          <ListItemText primary="Babysittings" />
        </ListItem>
        <ListItem button onClick={handleClickApplications}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Candidatures" />
        </ListItem>
        <ListItem button onClick={handleClickAbuses}>
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary="Signalements" />
        </ListItem>
      </List>
      <Divider />
      <List subheader={
          user &&
          <ListSubheader component="div" id="sidebar-subheader">
            Connecté en tant que <b>{user.first_name} {user.last_name}</b>
          </ListSubheader>
        }>
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Se déconnecter" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
