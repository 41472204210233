export default function getCurrency(babysitting: any): string {
  switch (babysitting.currency.toLowerCase()) {
    case "usd":
      return "$";
    case "gbp":
      return "£";
    default:
      return "€";
  }
}
